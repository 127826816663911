var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{staticClass:"ownerTemporalModel",attrs:{"title":_vm.title,"color":"warning","show":_vm.show,"data-cy":"cancel-modal","closeOnBackdrop":false,"centered":true,"size":"lg"},on:{"update:show":function($event){return _vm.$emit('onClose')}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{staticClass:"add-button",attrs:{"color":"primary","disabled":_vm.stillAvailableCount(_vm.tempCount) <= 0},on:{"click":function($event){return _vm.openAddTempModal()}}},[_c('CIcon',{attrs:{"name":"cilPlus"}}),_c('span',{staticClass:"add-text"},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.calendar.OwnerCalendar.addTemp', { stillAvailableCount: _vm.stillAvailableCount(_vm.tempCount), }))+" ")])],1),_c('CButton',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.calendar.playroomTemporal.close'),expression:"'pages.owner.calendar.playroomTemporal.close'"}],attrs:{"color":"light"},on:{"click":function($event){return _vm.$emit('onClose')}}})]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('DataTable',{attrs:{"items":_vm.unavailablePeriods,"fields":[
          {
            key: 'startTimeInUTC',
            label: _vm.$t('pages.owner.calendar.playroomTemporal.start'),
            sorter: false,
            filter: false,
          },
          {
            key: 'endTimeInUTC',
            label: _vm.$t('pages.owner.calendar.playroomTemporal.end'),
            sorter: false,
            filter: false,
          },
          {
            key: 'notes',
            label: _vm.$t('pages.owner.calendar.playroomTemporal.note'),
            sorter: false,
            filter: false,
          },
          {
            key: 'actions',
            label: _vm.$t('global.actions'),
          } ],"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"startTimeInUTC",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatIsoDateTimeTz(item.startTimeInUTC, 'Pp'))+" ")])]}},{key:"endTimeInUTC",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatIsoDateTimeTz(item.endTimeInUTC, 'Pp'))+" ")])]}},{key:"notes",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.unavailablePeriodNote ? item.unavailablePeriodNote : _vm.$t('pages.owner.calendar.TemporalAvailabilty.noNote'))+" ")])]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('a',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.calendar.TemporalAvailabilty.deleteBtn'),expression:"'pages.owner.calendar.TemporalAvailabilty.deleteBtn'"}],attrs:{"href":'javascript:void(0)'},on:{"click":function($event){return _vm.openDeleteModal(item)}}}),_vm._v(" | "),_c('a',{directives:[{name:"t",rawName:"v-t",value:('global.change'),expression:"'global.change'"}],attrs:{"href":'javascript:void(0)'},on:{"click":function($event){return _vm.openEditTempModal(item)}}})])]}}])})],1)],1),(_vm.showTempModal)?_c('OwnerChangeTemporalModal',{attrs:{"show":_vm.showTempModal,"title":_vm.popupTitle,"playroomId":_vm.playroomId,"unavailablePeriod":_vm.unavailablePeriodInModal},on:{"onClose":function($event){_vm.showTempModal = false},"onEditDelete":function($event){return _vm.onEditDelete()}}}):_vm._e(),_c('DeleteConfirmationModal',{attrs:{"show":_vm.showDeleteModal,"message":_vm.$t('components.messages.DeleteConfirmationModal.message')},on:{"delete":_vm.deleteTemp,"close":_vm.closeDeleteModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }