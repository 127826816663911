import Vue from 'vue';
import Vuex from 'vuex';
import AuthStoreModule from '@/store/modules/AuthStoreModule';
import ToasterStoreModule from '@/store/modules/ToasterStoreModule';
import BackendVersionStoreModule from '@/store/modules/BackendVersionStoreModule';
import AlertStoreModule from '@/store/modules/AlertStoreModule';
import StatusStoreModule from '@/store/modules/StatusStoreModule';
import UiStoreModule from '@/store/modules/UiStoreModule';
import SearchStoreModule from '@/store/modules/SearchStoreModule';
import MaintainanceStoreModule from '@/store/modules/MaintainanceStoreModule';
import ChatStoreModule from '@/store/modules/ChatStoreModule';
import NextActivitiesStoreModule from '@/store/modules/NextActivitiesStoreModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AuthStoreModule,
    ToasterStoreModule,
    BackendVersionStoreModule,
    AlertStoreModule,
    StatusStoreModule,
    UiStoreModule,
    SearchStoreModule,
    MaintainanceStoreModule,
    ChatStoreModule,
    NextActivitiesStoreModule,
  },
});
