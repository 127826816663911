











import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import { SaveStatusType } from '@/enums/SaveStatusType';

const validations = {
  guestCount: {
    minValue: minValue(2),
    maxValue: maxValue(30),
    required,
  },
};

export default Vue.extend({
  components: { ValidatedInput },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      guestCount: 2,
    };
  },
  watch: {
    guestCount: {
      handler() {
        this.emitChange(Number(this.guestCount));
      },
    },
  },
  mounted() {
    this.guestCount = this.$store.state.SearchStoreModule.searchParameter.guestCount;
  },
  methods: {
    emitChange(guestCount: Number) {
      this.$store.commit('SearchStoreModule/setGuestCount', guestCount);
      this.$emit('change', guestCount);
      if (this.$v.$invalid) {
        this.$emit('save-status-type-change', SaveStatusType.Invalid);
      } else {
        this.$emit('save-status-type-change', SaveStatusType.Valid);
      }
    },
  },
});
