import ApiService from '@/services/ApiService';

const API_URL = '/api/Public/Support/';

class PublicSupportService {
  sendEmail(supporttype: string, subject: string, content: string, visitorName: string, visitorEmail: string) {
    return ApiService.post(`${API_URL}sendEmail`, { supporttype, subject, content, visitorName, visitorEmail });
  }
}

export default new PublicSupportService();
