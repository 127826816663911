import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CoreuiVue from '@coreui/vue-pro';
import {
  cilCardTravel,
  cilExclamationCircle,
  cisStar,
  cilPerson,
  cisHeart,
  cisEuro,
  cisEuroSlash,
  cilDelete,
  cisHamburgerMenu,
  cidXCircle,
  cisFilePdf,
  cisInfoCircle,
  cisLockUnlocked,
  cisLockLocked,
  cisLocationPin,
  cisKeyAlt,
  cilInfoCircle,
  cisSmoke,
  cisSmokeFree,
  cilCash,
  cibCcPaypal,
  cilDocument,
  cilUser,
  cilLockLocked,
  cilLocationPin,
  cilMagnifyingGlass,
  cilHouse,
  cilCart,
  cilPeople,
  cilCalendar,
  cilBuilding,
  cilEnvelopeClosed,
  cilDollar,
  cilChart,
  cilHome,
  cilAccountLogout,
  cilSettings,
  cilStar,
  cilApplications,
  cilAt,
  cilWarning,
  cilTags,
  cilHeart,
  cilBan,
  cilCamera,
  cilPlus,
  cifDe,
  cifGb,
  cilSave,
  cilTask,
  cilCreditCard,
  cisPlus,
  cisMinus,
  cilCheck,
  cidPlaylistAddCheck,
  cisLaptop,
  cisMediaPlayCircle,
  cisSearch,
  cisDollarCircle,
  cisList,
  cilArrowRight,
  cilFeaturedPlaylist,
  cilRoom,
  cilNote,
  cilCalendarPlus,
  cilGlobe,
  cilChatBubble,
  cilWidgets,
  cisCardMembership,
} from '@coreui/icons-pro';
import moment from 'moment';
import VueMoment from 'vue-moment';
import 'moment/locale/de';
declare module 'vue-application-insights';
import VueAppInsights from 'vue-application-insights';
import i18n from './i18n';
import VCalendar from 'v-calendar';
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta';
import CoreuiVueCharts from '@coreui/vue-chartjs';
import * as VueGoogleMaps from 'vue2-google-maps-withscopedautocomp';
import VueClipboard from 'vue-clipboard2';
import SignalRPlugin from '@/plugins/signalRPlugin';
import vueBraintree from 'vue-braintree';

Vue.use(vueBraintree);
Vue.use(SignalRPlugin);
Vue.use(VueClipboard);
Vue.use(VueMeta);
Vue.use(VueCookies);

Vue.$cookies.config('0', '', process.env.VUE_APP_COOKIE_DOMAIN); // Default expire Cookie after Session

declare module 'vue/types/vue' {
  interface Vue {
    $appInsights: any;
  }
}

if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'staging') {
  Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_APPLICATION_INSIGHTS,
    router,
    baseName: 'Frontend ' + process.env.NODE_ENV,
    onAfterScriptLoaded: () => {
      Vue.config.errorHandler = (err, vm) => {
        vm.$appInsights.trackException({ exception: err });
      };
    },
  });
}

Vue.config.devtools = process.env.NODE_ENV != 'production';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-164272530-1' },
  },
  router
);

Vue.use(CoreuiVue);
Vue.config.productionTip = true;
Vue.prototype.$log = console.log.bind(console);

Vue.use(VueMoment, {
  moment,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB-4Agb41LgWfUyVvmysLbEOvd9iZGpfmk',
    libraries: 'places',
  },
  installComponents: true,
});
Vue.use(CoreuiVueCharts);
new Vue({
  router,
  store,
  icons: {
    cilUser,
    cilLockLocked,
    cilLocationPin,
    cilMagnifyingGlass,
    cilHouse,
    cilCart,
    cilPeople,
    cilCalendar,
    cilBuilding,
    cilEnvelopeClosed,
    cilDollar,
    cilChart,
    cilHome,
    cilAccountLogout,
    cilSettings,
    cilStar,
    cilApplications,
    cilAt,
    cilWarning,
    cilTags,
    cilCardTravel,
    cilExclamationCircle,
    cisStar,
    cilPerson,
    cisHeart,
    cilHeart,
    cilBan,
    cilPlus,
    cilCamera,
    cisEuro,
    cisEuroSlash,
    cifDe,
    cifGb,
    cilDelete,
    cisHamburgerMenu,
    cilSave,
    cidXCircle,
    cisFilePdf,
    cisInfoCircle,
    cilTask,
    cisLockUnlocked,
    cisLockLocked,
    cisLocationPin,
    cisKeyAlt,
    cilInfoCircle,
    cisSmoke,
    cisSmokeFree,
    cilCash,
    cilCreditCard,
    cibCcPaypal,
    cilDocument,
    cisMinus,
    cisPlus,
    cilCheck,
    cidPlaylistAddCheck,
    cisLaptop,
    cisMediaPlayCircle,
    cisSearch,
    cisDollarCircle,
    cisList,
    cilArrowRight,
    cilFeaturedPlaylist,
    cilRoom,
    cilNote,
    cilCalendarPlus,
    cilGlobe,
    cilChatBubble,
    cilWidgets,
    cisCardMembership,
  },
  i18n,
  render: (h) => h(App),
}).$mount('#app');
