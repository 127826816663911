








































import Vue from 'vue';
import ValidatedInputErrors from './ValidatedInputErrors.vue';
import nanoid from 'nanoid';

export default Vue.extend({
  components: { ValidatedInputErrors },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    label: { type: String },
    placeholder: { type: String },
    description: { type: String },
    id: { type: String },
    translationKey: { type: String },
  },
  data() {
    return {
      lengthDescription: '',
      randomId: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.maxLength) {
          const currentValue = this.value as string;
          let currentLength = 0;
          if (currentValue !== null && currentValue !== undefined) {
            currentLength = currentValue.length;
          }
          this.lengthDescription = currentLength + '/' + this.maxLength;
        }
      },
    },
  },
  computed: {
    checkIfValid(): boolean | null {
      if (this.field === undefined || !this.field.$dirty) {
        return null;
      }
      if (this.field.$invalid) {
        return false;
      }
      if (this.field.$model === '' && this.isRequired) {
        return false;
      }
      return true;
    },
    maxLength(): string {
      return this.field.$params?.maxLength?.max;
    },
    shownLabel(): string {
      if (this.label !== undefined) {
        return this.label;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('label');
      } else {
        return this.label;
      }
    },
    shownDescription(): string {
      if (this.description !== undefined) {
        return this.description;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('description');
      } else {
        return this.description;
      }
    },
    isRequired(): boolean {
      return this.field.required !== undefined;
    },
    name(): string {
      return this.translationKey.split('.').reverse()[0];
    },
  },

  methods: {
    getTranslation(key: string): string {
      const translationKey = 'forms.' + this.translationKey + '.' + key;
      return this.$te(translationKey) ? this.$t(translationKey).toString() : '';
    },

    onChange(state: string) {
      this.$emit('update:value', state);
    },

    keyUpEvent(event: any) {
      this.$emit('update:value', event.target.value);
    },

    safeId(): string {
      if (this.id === undefined) {
        if (this.randomId === '') {
          this.randomId = nanoid();
        }
        return this.randomId;
      } else {
        return this.id;
      }
    },
  },
});
