





































































































import Jumbotron from '@/components/shared/Jumbotron.vue';
import SearchBar from '@/components/playroomSearch/SearchBar.vue';
import SearchParameter from '@/models/SearchParameter';
import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import SearchStoreModule from '@/store/modules/SearchStoreModule';
import SupportModal from '@/components/shared/SupportModal.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: { Jumbotron, SearchBar, SupportModal },
  mounted() {
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.public.Home.meta.title').toString() };
  },
  data() {
    return {
      searchParameter: new SearchParameter(),
      showSupportModal: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.AuthStoreModule.status.loggedIn;
    },
    backgroundImage(): string {
      return '/images/homepage/feet.png';
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'Home' }, translationKey: 'pages.public.Home.breadcrumb.last' },
      ]);
    },

    onSearchParameterChange(searchParameter: SearchParameter) {
      const searchStoreModule = getModule(SearchStoreModule, this.$store);
      searchStoreModule.saveSearchParameter(searchParameter);
      this.$router.push({ name: 'PublicPlayroomIndex' });
    },
  },
});
