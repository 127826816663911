

























































































import Vue, { PropType, VueConstructor } from 'vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import OwnerOrderCalendarService from '@/services/owner/OwnerOrderCalendarService';
import { validationMixin } from 'vuelidate';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import addMinutes from 'date-fns/addMinutes';
import { format } from 'date-fns-tz';
import de from 'date-fns/locale/de';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { addDays, isSameDay } from 'date-fns';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { UnavailablePeriod } from './OwnerPlayroomTemporalModal.vue';

const validations = {
  unavailablePeriod: {
    id: {},
    unavailablePeriodNote: {
      minLength: minLength(5),
      maxLength: maxLength(500),
    },
  },

  startDateString: {
    required,
  },
  startTimeString: {
    required,
  },
  endDateString: {
    required,
  },
  endTimeString: {
    required,
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [validationMixin, FormaterMixin],
  components: { ValidatedTextArea, DeleteButton },
  validations,
  props: {
    unavailablePeriod: {
      type: Object as PropType<UnavailablePeriod>,
      required: true,
    },
    playroomId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      startDate: new Date(),
      startTimeString: '00:00',
      endDate: new Date(),
      endTimeString: '01:00',
    };
  },
  watch: {
    startDate: {
      handler() {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      },
    },
    unavailablePeriod: {
      immediate: true,
      handler() {
        const startDateTime = this.parseIsoDateTimeTz(this.unavailablePeriod.startTimeInUTC);
        if (startDateTime) {
          this.startDate = startDateTime;
          this.startTimeString = format(startDateTime, 'HH:mm');
        }
        const endDateTime = this.parseIsoDateTimeTz(this.unavailablePeriod.endTimeInUTC);
        if (endDateTime) {
          this.endDate = endDateTime;
          this.endTimeString = format(endDateTime, 'HH:mm');
        }
      },
    },
  },
  computed: {
    timeOptions(): { value: string; label: string }[] {
      const timeOptions = [];
      for (var i = 0; i < 24; i++) {
        const minsFromMidnight = i * 60;
        const newDate = addMinutes(new Date(2000, 1, 1, 0), minsFromMidnight);
        var label = format(newDate, 'p', { locale: de });
        timeOptions.push({ label, value: label });
      }
      return timeOptions;
    },
    maxDuration(): Date {
      return addDays(this.startDate, 30);
    },
    untilTimes(): { value: string; label: string }[] {
      const untilTimes = [];
      if (this.startDate) {
        let hour = 0;
        if (isSameDay(this.startDate, this.endDate)) {
          hour = Number(this.startTimeString.split(':')[0]);
          hour += 1;
        }
        for (let index = hour; index < 24; index++) {
          untilTimes.push({ value: `${('0' + index).slice(-2)}:00`, label: `${('0' + index).slice(-2)}:00` });
        }
      }
      return untilTimes;
    },
  },
  methods: {
    save() {
      OwnerOrderCalendarService.editTemporalAvailability(
        this.unavailablePeriod.id,
        this.playroomId,
        this.dateToString(this.startDate),
        this.dateToString(this.endDate),
        this.startTimeString,
        this.endTimeString,
        this.unavailablePeriod.unavailablePeriodNote
      ).then(() => {
        this.$emit('onEditDelete');
      });
    },
    dateToString(dt: Date | string): string {
      return format(dt, 'dd.MM.yyyy', { locale: de, timeZone: 'Europe/Berlin' });
    },
    timeToString(dt: Date | string): string {
      return format(dt, 'HH:mm', { locale: de, timeZone: 'Europe/Berlin' });
    },
    deleteTemp() {
      OwnerOrderCalendarService.deleteTemporalAvailability(this.unavailablePeriod.id, this.playroomId).then(() => {
        this.$emit('onEditDelete');
      });
    },
  },
});
