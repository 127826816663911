import store from '@/store';
import CustomerStatusService from '@/services/customers/CustomerStatusService';
import AdminStatusService from '@/services/admin/AdminStatusService';
import OwnerStatusService from '@/services/owner/OwnerStatusService';

class StatusUpdater {
  async loadData(): Promise<boolean> {
    const isLoggedIn = store.state.AuthStoreModule.status.loggedIn;
    if (isLoggedIn && !document.hidden) {
      const role = store.state.AuthStoreModule.user.role;
      switch (role) {
        case 'Admin': {
          return this.updateAdmin();
        }
        case 'Customer': {
          return this.updateCustomer();
        }
        case 'Owner': {
          return this.updateHost();
        }
        default:
          return Promise.resolve(false);
      }
    }
    return Promise.resolve(true);
  }

  private async updateAdmin(): Promise<boolean> {
    try {
      const resp = await AdminStatusService.getStatus();
      store.commit('StatusStoreModule/setAdminStatus', resp.value);
      return true;
    } catch (ex) {
      return false;
    }
  }

  private async updateHost(): Promise<boolean> {
    try {
      const resp = await OwnerStatusService.getStatus();
      store.commit('StatusStoreModule/setOwnerStatus', resp.value);
      return true;
    } catch (ex) {
      return false;
    }
  }

  private async updateCustomer(): Promise<boolean> {
    try {
      const resp = await CustomerStatusService.getStatus();
      store.commit('StatusStoreModule/setCustomerStatus', resp.value);
      return true;
    } catch (ex) {
      return false;
    }
  }
}

export default new StatusUpdater();
