












import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      rating: '0',
      ratingValue: ['0', '45', '40', '35', '30'],
    };
  },
  watch: {
    rating: {
      handler(oldValue: string, newValue: string) {
        if (oldValue !== newValue) {
          this.$store.commit('SearchStoreModule/setMinRating', this.rating);
        }
        this.emitChange();
      },
    },
    ratingSearchParameter() {
      this.updateFilterData();
    },
  },
  computed: {
    ratingSearchParameter(): string {
      return this.$store.state.SearchStoreModule.searchParameter.minRating;
    },
    ratingValueOptions(): { value: string; label: string }[] {
      return this.ratingValue.map((t) => ({
        value: t,
        label: this.$t('enums.playroomRating.' + t).toString(),
      }));
    },
  },
  mounted() {
    this.$parent.$on('updateRating', this.setValue);
  },
  methods: {
    updateFilterData() {
      this.rating = this.ratingSearchParameter;
    },
    emitChange() {
      this.$emit('change', +this.rating / 10);
    },
    setValue(isRating: boolean) {
      if (isRating) {
        this.rating = '40';
      } else {
        this.rating = '0';
      }
    },
  },
});
