const LoginView = () => import('@/views/auth/LoginView.vue');
const RegisterCustomer = () => import('@/views/auth/RegisterCustomer.vue');
const RegisterOwner = () => import('@/views/auth/RegisterOwner.vue');
const ConfirmEmail = () => import('@/views/auth/ConfirmEmail.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');
const ResetPassword = () => import('@/views/auth/ResetPassword.vue');
const ResendConfirmation = () => import('@/views/auth/ResendConfirmation.vue');

export default [
  {
    path: '/auth/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/auth/register/customer',
    name: 'RegisterCustomer',
    component: RegisterCustomer,
  },
  {
    path: '/auth/register/owner',
    name: 'RegisterOwner',
    component: RegisterOwner,
  },
  {
    path: '/auth/confirmEmail/:userId/:token',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    props: true,
  },
  {
    path: '/auth/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/auth/ResendConfirmation',
    name: 'ResendConfirmation',
    component: ResendConfirmation,
  },
  {
    path: '/auth/ResetPassword/:userId/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true,
  },
];
