





































import Vue from 'vue';
import ValidatedInputErrors from './ValidatedInputErrors.vue';
import nanoid from 'nanoid';

export default Vue.extend({
  components: { ValidatedInputErrors },
  props: {
    field: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    translationKey: {
      type: String,
      required: false,
    },
    testId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      randomId: '',
    };
  },
  computed: {
    checkIfValid(): boolean | null {
      if (this.field === undefined || !this.field.$dirty) {
        return null;
      }
      return !(this.field.$invalid || this.field.$model === '');
    },

    shownLabel(): string {
      if (this.label !== undefined) {
        return this.label;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('label');
      } else {
        return this.label;
      }
    },

    shownTestId(): string {
      if (this.testId) {
        return this.testId;
      } else if (this.translationKey) {
        return this.translationKey.replaceAll('.', '-');
      }
      return '';
    },

    shownDescription(): string {
      if (this.description !== undefined) {
        return this.description;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('description');
      } else {
        return this.description;
      }
    },

    isRequired(): boolean {
      return this.field.required !== undefined;
    },
  },
  methods: {
    getTranslation(key: string): string {
      const translationKey = 'forms.' + this.translationKey + '.' + key;
      return this.$te(translationKey) ? this.$t(translationKey).toString() : '';
    },

    safeId(): string {
      if (this.id === undefined) {
        if (this.randomId === '') {
          this.randomId = nanoid();
        }
        return this.randomId;
      } else {
        return this.id;
      }
    },

    updatedChecked(state: boolean) {
      this.field.$touch();
      this.$emit('update:checked', state);
      this.$emit('change', state);
    },
  },
});
