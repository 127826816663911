const ChangePassword = () => import('@/views/user/ChangePassword.vue');
const ChangeEmail = () => import('@/views/user/ChangeEmail.vue');
const ConfirmChangeEmail = () => import('@/views/user/ConfirmChangeEmail.vue');

export default [
  {
    path: '/User/ChangePassword',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/User/ChangeEmail',
    name: 'ChangeEmail',
    component: ChangeEmail,
  },
  {
    path: '/User/ConfirmChangeEmail/:newEmail/:userId/:token',
    name: 'ConfirmChangeEmail',
    component: ConfirmChangeEmail,
    props: true,
  },
];
