





















import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import { mapState } from 'vuex';

const validations = {
  smokingAllowed: {},
  partyEventAllowed: {},
  commercialUsageAllowed: {},
};

export default Vue.extend({
  components: { ValidatedCheckbox },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      smokingAllowed: false,
      partyEventAllowed: false,
      commercialUsageAllowed: false,
    };
  },
  mounted() {
    this.updateFilterData();
  },
  watch: {
    smokingAllowed: {
      immediate: true,
      handler() {
        this.emitChange();
      },
    },
    partyEventAllowed: {
      immediate: true,
      handler() {
        this.emitChange();
      },
    },
    commercialUsageAllowed: {
      immediate: true,
      handler() {
        this.emitChange();
      },
    },
    searchParameter: {
      deep: true,
      handler() {
        this.updateFilterData();
      },
    },
  },
  computed: {
    ...mapState('SearchStoreModule', ['searchParameter']),
    savedSmokingAllowed(): boolean {
      return this.searchParameter.houseRules.includes('SmokingAllowed');
    },
    savedPartyEventAllowed(): boolean {
      return this.searchParameter.houseRules.includes('PartyEventAllowed');
    },
    savedCommercialUsageAllowed(): boolean {
      return this.searchParameter.houseRules.includes('CommercialUsageAllowed');
    },
  },
  methods: {
    updateFilterData() {
      this.smokingAllowed = this.savedSmokingAllowed;
      this.partyEventAllowed = this.savedPartyEventAllowed;
      this.commercialUsageAllowed = this.savedCommercialUsageAllowed;
    },
    emitChange() {
      const houseRules: string[] = [];
      const reload =
        this.smokingAllowed != this.savedSmokingAllowed ||
        this.partyEventAllowed != this.savedPartyEventAllowed ||
        this.commercialUsageAllowed != this.savedCommercialUsageAllowed;
      if (this.smokingAllowed) {
        houseRules.push('SmokingAllowed');
      }
      if (this.partyEventAllowed) {
        houseRules.push('PartyEventAllowed');
      }
      if (this.commercialUsageAllowed) {
        houseRules.push('CommercialUsageAllowed');
      }
      if (reload) {
        this.$store.commit('SearchStoreModule/setHouseRules', houseRules);
        this.$emit('change');
      }
    },
  },
});
