import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import AlertMessage from '@/models/AlertMessage';

@Module
export default class AlertStoreModule extends VuexModule {
  alerts: AlertMessage[] = [];

  @Mutation
  addAlertMessage(alert: AlertMessage) {
    this.alerts.push(alert);
  }

  @Mutation
  deleteAlerts() {
    this.alerts = [];
  }

  @Action({ commit: 'deleteAlerts' })
  getAlerts() {
    return this.alerts;
  }
}
