



















import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import addDays from 'date-fns/addDays';
import parse from 'date-fns/parse';
import beforeCheckOutValidator from '@/validators/BeforeCheckOutValidator';
import afterCheckInValidator from '@/validators/AfterCheckInValidator';
import { format } from 'date-fns';
import SameOrAfterCurrentDateValidator from '@/validators/SameOrAfterCurrentDateValidator';

const maxDateValidationEnd = (value: string): boolean => {
  if (!value) {
    return true;
  }
  const start = new Date();
  var parsedDate = parse(value, 'yyyy-MM-dd', start);
  if (parsedDate < start) {
    return true;
  }
  const end = addDays(start, 365);
  return parsedDate < end;
};

const maxDateValidationStart = (value: string): boolean => {
  if (!value) {
    return true;
  }
  const start = new Date();
  var parsedDate = parse(value, 'yyyy-MM-dd', start);
  const end = addDays(start, 365);
  return parsedDate < end;
};

export default Vue.extend({
  components: { ValidatedInput },
  mixins: [validationMixin],
  validations() {
    const val = {
      checkInDate: {},
      checkOutDate: {},
    };
    if (!!this.checkOutDate) {
      val.checkInDate = {
        SameOrAfterCurrentDateValidator,
        maxDateValidation: maxDateValidationStart,
        beforeCheckOut: beforeCheckOutValidator,
        required,
      };
    } else {
      val.checkInDate = {
        SameOrAfterCurrentDateValidator,
        maxDateValidation: maxDateValidationStart,
        beforeCheckOut: beforeCheckOutValidator,
      };
    }
    if (!!this.checkInDate) {
      val.checkOutDate = {
        SameOrAfterCurrentDateValidator,
        maxDateValidation: maxDateValidationEnd,
        afterCheckIn: afterCheckInValidator,
        required,
      };
    } else {
      val.checkOutDate = {
        SameOrAfterCurrentDateValidator,
        maxDateValidation: maxDateValidationEnd,
        afterCheckIn: afterCheckInValidator,
      };
    }
    return val;
  },
  data() {
    return {
      checkInDate: '',
      checkOutDate: '',
      ready: false,
    };
  },
  mounted() {
    this.ready = true;
    this.checkInDate = this.savedCheckInDate;
    this.checkOutDate = this.savedCheckOutDate;
  },
  computed: {
    savedCheckInDate(): string {
      return this.$store.state.SearchStoreModule.searchParameter.checkInDate;
    },
    savedCheckOutDate(): string {
      return this.$store.state.SearchStoreModule.searchParameter.checkOutDate;
    },
    bookingType(): string {
      return this.$store.state.SearchStoreModule.searchParameter.bookingType;
    },
  },
  watch: {
    savedCheckInDate() {
      this.checkInDate = this.savedCheckInDate;
    },
    savedCheckOutDate() {
      this.checkOutDate = this.savedCheckOutDate;
    },
    checkInDate: {
      handler() {
        if (!!this.checkInDate && !this.checkOutDate) {
          const parsedDate = parse(this.checkInDate, 'yyyy-MM-dd', new Date());
          this.checkOutDate = format(addDays(parsedDate, 1), 'yyyy-MM-dd');
        }
        if (!!this.checkInDate || !!this.checkOutDate) {
          const checkInDate = parse(this.checkInDate, 'yyyy-MM-dd', new Date());
          const checkOutDate = parse(this.checkOutDate, 'yyyy-MM-dd', new Date());
          if (checkInDate >= checkOutDate) {
            this.checkOutDate = format(addDays(checkInDate, 1), 'yyyy-MM-dd');
          }
          this.$v.$touch();
        }
        this.emitChange();
      },
    },
    checkOutDate: {
      handler() {
        if (!!this.checkInDate || !!this.checkOutDate) {
          this.$v.$touch();
        }
        this.emitChange();
      },
    },
  },
  methods: {
    emitChange() {
      if (this.bookingType === 'overnight') {
        this.$store.commit('SearchStoreModule/setDates', {
          checkInDate: this.checkInDate,
          checkOutDate: this.checkOutDate,
          checkInIsValid: !this.$v.$invalid,
        });
        this.$emit('change', !this.$v.$invalid);
      }
    },

    clearData() {
      this.checkInDate = '';
      this.checkOutDate = '';
    },
  },
});
