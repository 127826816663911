























import Vue from 'vue';
import TheSidebar from '@/components/shared/TheSidebar.vue';
import TheHeader from '@/components/shared/TheHeader.vue';
import TheFooter from '@/components/shared/TheFooter.vue';
import DemoHeader from '@/components/shared/DemoHeader.vue';
import ToasterComponent from '@/components/shared/ToasterComponent.vue';
import Alerts from '@/components/shared/Alerts.vue';
import StatusUpdater from '@/services/StatusUpdater';
import NextActivities from '@/components/shared/NextActivities.vue';
import { mapState } from 'vuex';

declare module 'vue/types/vue' {
  interface Vue {
    $messaging: any;
  }
}

export default Vue.extend({
  components: { TheHeader, TheSidebar, ToasterComponent, Alerts, TheFooter, DemoHeader, NextActivities },
  metaInfo() {
    return {
      titleTemplate(titleChunk: string): string {
        let title = 'My Playrooms';
        if (process.env.VUE_APP_ENV_NAME != 'Production') {
          title += ' ' + process.env.VUE_APP_ENV_NAME;
        }
        if (titleChunk == '' || titleChunk == null) {
          return title;
        }
        if (titleChunk.includes('My Playrooms')) {
          return titleChunk;
        } else {
          return titleChunk + ' | ' + title;
        }
      },
    };
  },
  data() {
    return {
      interval: 0,
    };
  },
  computed: {
    ...mapState('AuthStoreModule', ['user']),
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
  },
  mounted() {
    StatusUpdater.loadData();
    this.$store.dispatch('NextActivitiesStoreModule/init');
    if (process.env.VUE_APP_STATUS_UPDATE_INTERVAL !== 'false') {
      this.interval = setInterval(() => {
        StatusUpdater.loadData().then((success) => {
          if (!success) {
            clearInterval(this.interval);
          }
        });
      }, parseInt(process.env.VUE_APP_STATUS_UPDATE_INTERVAL));
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
});
