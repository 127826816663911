import User from '@/models/user';
import Vue from 'vue';
import AdminUserService from './admin/AdminUserService';
import ApiService from './ApiService';
import { set } from 'vue-gtag';

class AuthService {
  ResendConfirmation(email: string) {
    return ApiService.post('/api/auth/ResendConfirmation', {
      email: email,
    });
  }

  confirmChangeEmail(userId: string, token: string, newEmail: string) {
    return ApiService.post('/api/auth/ConfirmChangeEmail', {
      userId: userId,
      token: token,
      newEmail: newEmail,
    });
  }

  changePassword(data: { password: string; newPassword: string; newPasswordConfirmation: string }) {
    return ApiService.post('/api/auth/ChangePassword', data);
  }

  changeEmail(data: { password: string; newEmail: string }) {
    return ApiService.post('/api/auth/RequestChangeEmail', data);
  }

  resetPassword(data: { password: string; passwordConfirmation: string; userId: string; token: string }) {
    return ApiService.post('/api/auth/ResetPassword', data);
  }

  forgotPassword(email: string) {
    return ApiService.post('/api/auth/forgotPassword', {
      email: email,
    });
  }

  loginAs(userName: any): Promise<any> {
    return AdminUserService.loginAs(userName).then((data: any) => {
      if (!data.value.token) {
        return Promise.reject('No Token');
      }
      Vue.$cookies.set('oldUser', Vue.$cookies.get('user') as string);
      Vue.$cookies.remove('user');
      Vue.$cookies.set('user', data.value);
      return Promise.resolve(data);
    }, Promise.reject);
  }

  async login(user: User): Promise<any> {
    const response = await ApiService.post('/api/auth/signin', user);
    return new Promise((resolve, reject) => {
      if (!response.success) {
        return reject(response.error);
      }
      if (!response.value.token) {
        return reject('No Token');
      }
      const cookieExpiresIn = user.rememberMe ? '30d' : '0';
      Vue.$cookies.set('user', response.value, cookieExpiresIn);
      set({ dimension1: response.value.role });
      return resolve(response.value);
    });
  }

  logout() {
    Vue.$cookies.remove('user');
  }

  backToAdmin(): User {
    const user = Vue.$cookies.get('oldUser') as User;
    Vue.$cookies.set('user', user);
    Vue.$cookies.remove('oldUser');
    return user;
  }

  register(user: User): Promise<any> {
    return ApiService.post('/api/auth/signup', {
      username: user.userName,
      password: user.password,
    });
  }
}

export default new AuthService();
