











import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';

const validations = {
  onlyFavorites: {},
};

export default Vue.extend({
  components: { ValidatedCheckbox },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      onlyFavorites: this.$store.state.SearchStoreModule.searchParameter.onlyFavorites,
    };
  },
  mounted() {
    this.updateFilterData();
  },
  watch: {
    onlyFavorites: {
      handler() {
        this.emitChange();
      },
    },
    favoriteSearchParameter() {
      this.updateFilterData();
    },
  },
  computed: {
    favoriteSearchParameter(): boolean {
      return this.$store.state.SearchStoreModule.searchParameter.onlyFavorites;
    },
  },
  methods: {
    updateFilterData() {
      this.onlyFavorites = this.favoriteSearchParameter;
    },
    emitChange() {
      this.$store.commit('SearchStoreModule/setOnlyFavorites', this.onlyFavorites);
      this.$emit('change');
    },
  },
});
