
















































































































import Vue, { VueConstructor } from 'vue';
import OwnerOrderCalendarService from '@/services/owner/OwnerOrderCalendarService';
import DataTable from '@/components/shared/DataTable.vue';
import OwnerChangeTemporalModal from '@/views/owner/calendar/OwnerChangeTemporalModal.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import de from 'date-fns/locale/de';
import { format } from 'date-fns-tz';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';

export class UnavailablePeriod {
  id!: string;
  startTimeInUTC!: string;
  endTimeInUTC!: string;
  unavailablePeriodNote!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { DataTable, OwnerChangeTemporalModal, DeleteConfirmationModal },
  mixins: [FormaterMixin],
  props: {
    playroomId: { type: String, required: true },
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      unavailablePeriods: [] as UnavailablePeriod[],
      unavailablePeriod: {} as UnavailablePeriod,
      loading: false,
      tempCount: 0,
      sorterValue: { column: 'startTimeInUTC', asc: true },
      showTempModal: false,
      showDeleteModal: false,
      popupTitle: '',
      unavailablePeriodInModal: {} as UnavailablePeriod,
    };
  },
  watch: {
    show() {
      if (this.show == true) {
        this.dataLoad();
      }
    },
  },
  computed: {},
  methods: {
    dataLoad() {
      this.loading = true;
      OwnerOrderCalendarService.PlayroomTemporalAvailability(this.playroomId).then((res) => {
        this.unavailablePeriods = res.value as UnavailablePeriod[];
        this.loading = false;
      });
      let showDate = new Date();
      OwnerOrderCalendarService.playroomWithTempCount(
        new Date(showDate.getFullYear(), showDate.getMonth() - 1, 1),
        new Date(showDate.getFullYear(), showDate.getMonth() + 2, 1)
      ).then((res) => {
        this.tempCount = res.value.filter((f: any) => {
          return f.playroomId == this.playroomId;
        })[0].tempCount;
      });
    },
    stillAvailableCount(tempCount: number): number {
      return 50 - tempCount;
    },
    openAddTempModal() {
      this.unavailablePeriodInModal = new UnavailablePeriod();
      this.popupTitle = this.$t('pages.owner.calendar.TemporalAvailabilty.titleAdd').toString();
      this.showTempModal = true;
    },
    openEditTempModal(item: UnavailablePeriod) {
      this.unavailablePeriodInModal = item;
      this.popupTitle = this.$t('pages.owner.calendar.TemporalAvailabilty.titleEdit').toString();
      this.showTempModal = true;
    },
    timeToString(dt: Date | string): string {
      return format(new Date(dt), 'HH:mm', { locale: de, timeZone: 'Europe/Berlin' });
    },
    onEditDelete() {
      this.showTempModal = false;
      this.dataLoad();
    },
    deleteTemp() {
      OwnerOrderCalendarService.deleteTemporalAvailability(this.unavailablePeriod.id, this.playroomId).then(() => {
        this.closeDeleteModal();
        this.dataLoad();
      });
    },
    openDeleteModal(item: UnavailablePeriod) {
      this.unavailablePeriod = item;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
  },
});
