
import { formatDistance, parseISO } from 'date-fns';
import { format, utcToZonedTime, toDate } from 'date-fns-tz';
import { de } from 'date-fns/locale';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    formatCurrency(item: number, options: { signDisplay: 'auto' | 'always' } = { signDisplay: 'auto' }): string {
      if (isNaN(item)) return '';
      const result = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        signDisplay: options.signDisplay,
      }).format(item);
      return result;
    },

    formatNumber(item: number): string {
      if (isNaN(item)) return '';
      const result = new Intl.NumberFormat('de-DE').format(item);
      return result;
    },

    parseIsoDateTimeTz(dateString: string): Date | null {
      if (dateString == '' || dateString == null) return null;
      const date = toDate(dateString, { timeZone: 'UTC' });
      const zonedDate = utcToZonedTime(date, 'Europe/Berlin');
      return zonedDate;
    },

    formatIsoDateTimeTz(dateString: string, formatString = 'P | p'): string {
      const zonedDate = this.parseIsoDateTimeTz(dateString);
      if (zonedDate == null) return '';
      const formatedTime = format(zonedDate, formatString, { locale: de, timeZone: 'Europe/Berlin' });
      return formatedTime;
    },

    formatIsoDateTimeDistanceTz(dateString: string): string {
      if (dateString == '' || dateString == null) return '';
      const date = toDate(dateString, { timeZone: 'UTC' });
      const zonedDate = utcToZonedTime(date, 'Europe/Berlin');
      return formatDistance(zonedDate, new Date(), { locale: de, addSuffix: true });
    },

    // Obsolete
    formatIsoDateTime(dateString: string, formatString = 'Pp'): string {
      if (dateString == '' || dateString == null) return '';
      const date = parseISO(dateString);
      const result = format(date, formatString, { locale: de });
      return result;
    },

    formatDateTime(date: Date, formatString = 'Pp'): string {
      if (!this.isValidDate(date)) return '';
      const result = format(date, formatString, { locale: de });
      return result;
    },

    isValidDate(date: Date): boolean {
      const isValid = date == null || (date instanceof Date && date.getTime && !isNaN(date.getTime()));
      return isValid;
    },
  },
});
