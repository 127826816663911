









import Vue from 'vue';
import { mapState } from 'vuex';
import BookingPolicyFilterView from './BookingPolicyFilterView.vue';

class BookingPoliciesFilterModel {
  name!: string;
  hasFilter!: boolean;
}

export default Vue.extend({
  components: { BookingPolicyFilterView },
  data() {
    return {
      ready: false,
      bookingPolicies: [] as BookingPoliciesFilterModel[],
      possibleBookingPolicies: [
        'cashAccepted',
        'creditCardAccepted',
        'payPalAccepted',
        'invoiceAccepted',
        'noPrepayment',
        'noDeposit',
        'freeCancellation',
      ],
    };
  },
  mounted() {
    this.loadData();
    this.updateData();
  },
  watch: {
    searchParameter: {
      deep: true,
      handler() {
        this.updateData();
      },
    },
  },
  computed: {
    ...mapState('SearchStoreModule', ['searchParameter']),
    showFreeCancellation(): boolean {
      if (!this.searchParameter.bookingType) {
        return false;
      }
      if (this.searchParameter.bookingType == 'hourly') {
        return !!this.searchParameter.checkInTime;
      }
      if (this.searchParameter.bookingType == 'overnight') {
        return !!this.searchParameter.checkInDate;
      }
      return false;
    },
  },
  methods: {
    loadData() {
      this.possibleBookingPolicies.forEach((bookingPolicyName) => {
        this.bookingPolicies.push({
          name: bookingPolicyName,
          hasFilter: false,
        });
      });
      this.ready = true;
    },

    updateData() {
      this.bookingPolicies.forEach((element) => {
        if (this.searchParameter.bookingPolicies) {
          element.hasFilter = this.searchParameter.bookingPolicies.indexOf(element.name) > -1;
        }
      });
    },

    emitChange() {
      let bookingPolicyList = [] as string[];
      this.bookingPolicies.forEach((element) => {
        if (element.hasFilter) {
          bookingPolicyList.push(element.name);
        }
      });
      const newList = JSON.stringify(bookingPolicyList);
      const oldList = JSON.stringify(this.$store.state.SearchStoreModule.searchParameter.bookingPolicies);
      if (
        newList != oldList ||
        (bookingPolicyList.length == 0 &&
          this.$store.state.SearchStoreModule.searchParameter.bookingPolicies.length == 0)
      ) {
        this.$store.commit('SearchStoreModule/setBookingPolicies', bookingPolicyList);
        this.$emit('change');
      }
    },
  },
});
