










import Vue from 'vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';

export default Vue.extend({
  components: { DeleteConfirmationModal },
  props: {
    onDelete: {
      type: Function,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'icon',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    internalOnDelete() {
      this.closeModal();
      this.onDelete();
    },
  },
});
