









































































import Vue from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import PublicSupportService from '@/services/public/PublicSupportService';
import { email } from 'vuelidate/lib/validators';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Notification from '@/components/shared/Notification.vue';

export default Vue.extend({
  components: { ValidatedInput, ValidatedTextArea, ValidatedSelect, Notification },
  mixins: [validationMixin],
  validations() {
    const val = {
      contactSupport: {
        content: {
          required,
          maxLength: maxLength(500),
          minLength: minLength(5),
        },
        subject: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(5),
        },
        supportType: {
          required,
        },
        visitorName: {},
        visitorEmail: {},
      },
    };

    if (!this.$store.state.AuthStoreModule.user) {
      val.contactSupport.visitorName = { required };
      val.contactSupport.visitorEmail = { required, email };
    }
    return val;
  },
  data() {
    return {
      contactSupport: {
        supportType: '',
        subject: '',
        content: '',
        visitorName: '',
        visitorEmail: '',
      },
      showSuccessNotification: false,
      showErrorNotification: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    initSupportType: {
      type: String,
      required: false,
    },
    initSubject: {
      type: String,
      required: false,
    },
  },
  mounted() {
    if (this.initSupportType !== undefined) {
      this.contactSupport.supportType = this.initSupportType;
    }
    if (this.initSubject !== undefined) {
      this.contactSupport.subject = this.initSubject;
    }
  },
  computed: {
    options(): { label: string; value: string }[] {
      const keys = this.$t('components.SupportModal.' + this.role) as {};
      const categories = [{ value: '', label: '' }];
      for (const [key, value] of Object.entries(keys)) {
        categories.push({
          label: value as string,
          value: key,
        });
      }
      return categories;
    },
    loggedin(): boolean {
      return this.$store.state.AuthStoreModule.user;
    },
    role(): string {
      if (!this.loggedin) {
        return 'Visitor';
      }
      return this.$store.state.AuthStoreModule.user.role;
    },
  },
  methods: {
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('onClose');

        PublicSupportService.sendEmail(
          this.contactSupport.supportType,
          this.contactSupport.subject,
          this.contactSupport.content,
          this.contactSupport.visitorName,
          this.contactSupport.visitorEmail
        ).then(
          () => {
            this.showSuccessNotification = true;
          },
          () => {
            this.showErrorNotification = true;
          }
        );
        this.contactSupport.supportType = '';
        this.contactSupport.content = '';
        this.contactSupport.subject = '';
        this.contactSupport.visitorName = '';
        this.contactSupport.visitorEmail = '';
        this.$v.$reset();
      }
    },
  },
});
