import { RouteConfig } from 'vue-router';
const OwnerMessageIndex = () => import('@/views/owner/messages/OwnerMessageIndex.vue');
const OwnerMessageDetail = () => import('@/views/owner/messages/OwnerMessageDetail.vue');
const OwnerMoneyTransactionIndex = () => import('@/views/owner/moneyTransaction/OwnerMoneyTransactionIndex.vue');
const OwnerDashboardIndex = () => import('@/views/owner/dashboard/OwnerDashboardIndex.vue');
const OwnerCreateMessage = () => import('@/views/owner/messages/OwnerCreateMessage.vue');
const OwnerPlayroomEditContainer = () => import('@/views/owner/playrooms/edit/OwnerPlayroomEditContainer.vue');
const OwnerPlayroomEditBasicInformation = () =>
  import('@/views/owner/playrooms/edit/OwnerPlayroomEditBasicInformation.vue');
const OwnerPlayroomEditDetailInformation = () =>
  import('@/views/owner/playrooms/edit/OwnerPlayroomEditDetailInformation.vue');
const OwnerPlayroomEditAvailability = () => import('@/views/owner/playrooms/edit/OwnerPlayroomEditAvailability.vue');
const OwnerPlayroomEditPrice = () => import('@/views/owner/playrooms/edit/OwnerPlayroomEditPrice.vue');
const OwnerPlayroomEditRules = () => import('@/views/owner/playrooms/edit/OwnerPlayroomEditRules.vue');
const OwnerPlayroomEditPaymentAndInvoice = () =>
  import('@/views/owner/playrooms/edit/OwnerPlayroomEditPaymentAndInvoice.vue');
const OwnerPlayroomEditCheckAndFinish = () =>
  import('@/views/owner/playrooms/edit/OwnerPlayroomEditCheckAndFinish.vue');
const HostTemplateTextEdit = () => import('@/views/owner/templateTexts/HostTemplateTextEdit.vue');
const HostPaymentSettings = () => import('@/views/owner/payment/HostPaymentSettings.vue');
const HostTelegramModule = () => import('@/views/owner/modules/HostTelegramModule.vue');
const OwnerOrderDetail = () => import('@/views/owner/orders/OwnerOrderDetail.vue');

export default [
  {
    path: '/owner/payment/',
    name: 'HostPaymentSettings',
    component: HostPaymentSettings,
  },
  {
    path: '/owner/telegramModule',
    name: 'HostTelegramModule',
    component: HostTelegramModule,
  },
  {
    path: '/owner/templateTexts/edit/:templateTextId',
    name: 'HostTemplateTextEdit',
    component: HostTemplateTextEdit,
    props: true,
  },
  {
    path: '/owner/playrooms/:playroomId/edit/container',
    name: 'OwnerPlayroomEditContainer',
    component: OwnerPlayroomEditContainer,
    props: true,
    children: [
      {
        path: '/owner/playrooms/:playroomId/edit/basicInformation',
        name: 'OwnerPlayroomEditBasicInformation',
        component: OwnerPlayroomEditBasicInformation,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/detailInformation',
        name: 'OwnerPlayroomEditDetailInformation',
        component: OwnerPlayroomEditDetailInformation,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/availability',
        name: 'OwnerPlayroomEditAvailability',
        component: OwnerPlayroomEditAvailability,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/price',
        name: 'OwnerPlayroomEditPrice',
        component: OwnerPlayroomEditPrice,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/rules',
        name: 'OwnerPlayroomEditRules',
        component: OwnerPlayroomEditRules,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/paymentAndInvoice',
        name: 'OwnerPlayroomEditPaymentAndInvoice',
        component: OwnerPlayroomEditPaymentAndInvoice,
        props: true,
      },
      {
        path: '/owner/playrooms/:playroomId/edit/checkAndFinish',
        name: 'OwnerPlayroomEditCheckAndFinish',
        component: OwnerPlayroomEditCheckAndFinish,
        props: true,
      },
    ],
  },
  {
    path: '/owner/messages',
    name: 'OwnerMessageIndex',
    component: OwnerMessageIndex,
    props: true,
  },
  {
    path: '/owner/orders/:orderId',
    name: 'OwnerOrderDetail',
    component: OwnerOrderDetail,
    props: true,
  },
  {
    path: '/owner/messages/:messageId',
    name: 'OwnerMessageDetail',
    component: OwnerMessageDetail,
    props: true,
  },
  {
    path: '/owner/transactions',
    name: 'OwnerMoneyTransactionIndex',
    component: OwnerMoneyTransactionIndex,
    props: true,
  },
  {
    path: '/owner/dashboard',
    name: 'OwnerDashboardIndex',
    component: OwnerDashboardIndex,
    props: true,
  },
  {
    path: '/owner/CreateMessage/:receiverId',
    name: 'OwnerCreateMessage',
    component: OwnerCreateMessage,
    props: true,
  },
] as RouteConfig[];
