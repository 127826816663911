import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Module as Mod } from 'vuex';
import Vue from 'vue';

@Module({ namespaced: true })
export default class UiStoreModule extends VuexModule {
  darkModeEnabled = true;
  sidebarShow = 'responsive';
  sidebarMinimize = 'false';
  breadcrumbItems = [];
  webSocketStatus = 'Unknown';

  constructor(module: Mod<ThisType<UiStoreModule>, any>) {
    super(module);
    const storedDarkModeState = Vue.$cookies.get('darkModeEnabled');
    if (storedDarkModeState == null) {
      this.darkModeEnabled = true;
    } else {
      this.darkModeEnabled = storedDarkModeState == 'true';
    }
  }

  @Action({ commit: 'setDarkMode' })
  toggleDarkMode() {
    const newDarkMode = !this.darkModeEnabled;
    Vue.$cookies.set('darkModeEnabled', String(newDarkMode));
    return newDarkMode;
  }

  @Mutation
  setBreadcrumbItems(items: []) {
    this.breadcrumbItems = items;
  }

  @Mutation
  webSocketConnected() {
    this.webSocketStatus = 'Connected';
  }

  @Mutation
  webSocketDisconnected() {
    this.webSocketStatus = 'Disconnected';
  }

  @Mutation
  setDarkMode(newDarkMode: boolean) {
    this.darkModeEnabled = newDarkMode;
  }

  @Mutation
  toggleSidebarDesktop() {
    const sidebarOpened = ['true', 'responsive'].includes(this.sidebarShow);
    this.sidebarShow = sidebarOpened ? 'false' : 'responsive';
  }

  @Mutation
  toggleSidebarMobile() {
    const sidebarClosed = ['false', 'responsive'].includes(this.sidebarShow);
    this.sidebarShow = sidebarClosed ? 'true' : 'responsive';
  }

  @Mutation
  toggleSidebarMinimize() {
    this.sidebarMinimize = this.sidebarMinimize == 'true' ? 'false' : 'true';
  }

  @Mutation
  setSidebarShow(value: any) {
    this.sidebarShow = value.toString();
  }
}
