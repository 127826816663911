import { helpers } from 'vuelidate/lib/validators';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';

const afterCheckIn = helpers.withParams({ type: 'minDate' }, (value: string, object: { checkInDate: string }) => {
  if (!object.checkInDate || !value) {
    return true;
  }
  const checkOut = parse(value, 'yyyy-MM-dd', new Date());
  const checkIn = parse(object.checkInDate, 'yyyy-MM-dd', new Date());
  return isAfter(checkOut, checkIn);
});

export default afterCheckIn;
