import { RouteConfig } from 'vue-router';
import OwnerOrderCalendar from '@/views/owner/calendar/OwnerCalendar.vue';
const OwnerPlayroomIndex = () => import('@/views/owner/playrooms/OwnerPlayroomIndex.vue');
const HostChatMessageIndex = () => import('@/views/owner/chatMessages/HostChatMessageIndex.vue');
const HostChatMessageDetail = () => import('@/views/owner/chatMessages/HostChatMessageDetail.vue');
const OwnerOrderIndex = () => import('@/views/owner/orders/OwnerOrderIndex.vue');
const HostModulesIndex = () => import('@/views/owner/modules/HostModulesIndex.vue');
const AccountEdit = () => import('@/views/owner/account/AccountEdit.vue');
const HostTemplateTextIndex = () => import('@/views/owner/templateTexts/HostTemplateTextIndex.vue');
const HostVoucherIndex = () => import('@/views/owner/vouchers/HostVoucherIndex.vue');
const HostVoucherEdit = () => import('@/views/owner/vouchers/HostVoucherEdit.vue');

export default [
  {
    path: '/host/playrooms',
    name: 'OwnerPlayroomIndex',
    component: OwnerPlayroomIndex,
    meta: {
      label: () => 'Label3',
      breadcrumb: {
        label: 'Label2',
        text: 'Playrooms',
      },
    },
  },
  {
    path: '/host/vouchers/edit/:voucherId',
    name: 'HostVoucherEdit',
    component: HostVoucherEdit,
    props: true,
  },
  {
    path: '/host/vouchers/',
    name: 'HostVoucherIndex',
    component: HostVoucherIndex,
  },
  {
    path: '/host/calendar',
    name: 'OwnerOrderCalendar',
    component: OwnerOrderCalendar,
    props: true,
  },
  {
    path: '/host/chatMessages',
    name: 'HostChatMessageIndex',
    component: HostChatMessageIndex,
  },
  {
    path: '/host/modules/',
    name: 'HostModulesIndex',
    component: HostModulesIndex,
  },
  {
    path: '/host/templateTexts/',
    name: 'HostTemplateTextIndex',
    component: HostTemplateTextIndex,
  },
  {
    path: '/owner/chatMessages/:guestId',
    name: 'HostChatMessageDetail',
    component: HostChatMessageDetail,
    props: true,
  },
  {
    path: '/host/orders',
    name: 'OwnerOrderIndex',
    component: OwnerOrderIndex,
  },
  {
    path: '/host/account/edit',
    name: 'HostAccountEdit',
    component: AccountEdit,
    props: true,
  },
] as RouteConfig[];
