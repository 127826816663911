








































































































































import Vue, { PropType, VueConstructor } from 'vue';
import OwnerOrderCalendarService from '@/services/owner/OwnerOrderCalendarService';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import { validationMixin } from 'vuelidate';
import ToastMessage from '@/models/ToastMessage';

class CalendarPlayroomDto {
  id!: string;
  name!: string;
  icsCalendarUrl!: string;
  icsCalendarLastUpdateAt!: string;
}
const validations = {
  playroom: {
    icsCalendarUrl: {},
  },
  privateIcsStreamUrl: {},
  publicIcsStreamUrl: {},
  icsPrivateStreamUrlWithImportedEvents: {},
  icsPublicStreamUrlWithImportedEvents: {},
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [validationMixin, FormaterMixin],
  components: { ValidatedInput, ValidatedCheckbox },
  validations,
  data() {
    return {
      icsPrivateStreamUrlWithImportedEvents: false,
      icsPublicStreamUrlWithImportedEvents: false,
    };
  },
  props: {
    playroom: {
      type: Object as PropType<CalendarPlayroomDto>,
      required: true,
    },
    calendarToken: String,
    publicCalendarToken: String,
  },
  computed: {
    privateIcsStreamUrl(): string {
      let baseUrl = OwnerOrderCalendarService.getIcsPrivateStreamUrl(
        this.calendarToken,
        this.playroom.id,
        this.icsPrivateStreamUrlWithImportedEvents
      );
      return baseUrl;
    },

    publicIcsStreamUrl(): string {
      let baseUrl = OwnerOrderCalendarService.getIcsPublicStreamUrl(
        this.publicCalendarToken,
        this.playroom.id,
        this.icsPublicStreamUrlWithImportedEvents
      );

      return baseUrl;
    },
  },
  methods: {
    saveAndRefresh() {
      this.deleteIcsEntries().then(() => {
        OwnerOrderCalendarService.saveIcsUrl(this.playroom.id, this.playroom.icsCalendarUrl).then(() => {
          this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich gespeichert', 'bg-success'));
          OwnerOrderCalendarService.importCalendar(this.playroom.id).then(() => {
            this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich importiert', 'bg-success'));
            this.$emit('onRefresh');
          });
        });
      });
    },

    onCopyIcsPublicUrl() {
      const message = this.$t('pages.owner.calendar.OwnerCalendar.successCopied').toString();
      this.$store.commit('addToastMessage', new ToastMessage(message, 'bg-success'));
    },

    onCopyIcsPrivateUrl() {
      const message = this.$t('pages.owner.calendar.OwnerCalendar.successCopied').toString();
      this.$store.commit('addToastMessage', new ToastMessage(message, 'bg-success'));
    },

    deleteIcsEntries() {
      return OwnerOrderCalendarService.deleteIcsEntries(this.playroom.id).then(() => {
        this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich gelöscht', 'bg-success'));
        this.$emit('onRefresh');
      });
    },
  },
});
