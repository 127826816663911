














import Vue from 'vue';
import AccountService from '@/services/users/AccountService';

export default Vue.extend({
  data() {
    return { availableLanguages: ['de', 'en'] };
  },

  computed: {
    languageOptions(): { label: string; value: string; icon: string }[] {
      const currentLocale = this.$i18n.locale;
      var languages = [
        { label: 'DE', value: 'de', icon: 'cif-de' },
        { label: 'EN', value: 'en', icon: 'cif-gb' },
      ];
      languages = languages.filter((x) => x.value != currentLocale);
      return languages;
    },
  },
  watch: {
    '$store.state.AuthStoreModule.user': {
      deep: true,
      handler() {
        this.getLangPreference();
      },
    },
  },
  mounted() {
    this.getLangPreference();
  },
  methods: {
    changeLocale(lang: string) {
      if (this.$store.state.AuthStoreModule.user) {
        AccountService.updateLangPreference({ langPreference: lang });
      }

      this.$i18n.locale = lang;
      this.$store.state.AuthStoreModule.user.langPreference = lang;
      this.$gtag.event('languageSwitch', {
        event_label: lang,
        value: this.availableLanguages.indexOf(lang),
      });
    },
    getLangPreference() {
      if (this.$store.state.AuthStoreModule.user) {
        AccountService.getLangPreference().then((data: any) => {
          if (data.value?.langPreference) {
            this.$i18n.locale = data.value?.langPreference;
          }
        });
      } else {
        let lang = 'de';
        this.$i18n.locale = lang;
        this.$gtag.event('languageSwitch', {
          event_label: lang,
          value: this.availableLanguages.indexOf(lang),
        });
      }
    },
    iconName(lang: string) {
      switch (lang) {
        case 'de':
          return 'cif-de';
        case 'en':
          return 'cif-gb';
      }
    },
  },
});
