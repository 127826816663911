















































































































































































































































































































































import Vue from 'vue';
import LoginIcon from '@/assets/scss/images/login.svg';
import { Status } from '@/store/modules/StatusStoreModule';

export default Vue.extend({
  components: { LoginIcon },
  computed: {
    roleTranslation(): string {
      return this.$t('enums.userRoles.' + this.currentUser.role).toString();
    },

    show(): string | boolean {
      switch (this.$store.state.UiStoreModule.sidebarShow) {
        case 'responsive':
          return 'responsive';
        case 'true':
          return true;
        default:
          return false;
      }
    },

    canGoBackToAdmin(): boolean {
      return this.$store.state.AuthStoreModule.status.canGoBackToAdmin;
    },

    status(): Status {
      return this.$store.state.StatusStoreModule.status;
    },

    isLoggedIn(): boolean {
      return this.$store.state.AuthStoreModule.status.loggedIn;
    },

    minimize(): string | boolean {
      switch (this.$store.state.UiStoreModule.sidebarMinimize) {
        case 'responsive':
          return 'responsive';
        case 'true':
          return true;
        default:
          return false;
      }
    },

    currentUser(): { role: string } {
      return this.$store.state.AuthStoreModule.user;
    },

    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },

    maintenanceMode(): boolean {
      return process.env.VUE_APP_MAINTENANCE_MODE == 'true';
    },

    unreadChatMessageCount(): number {
      return this.status.unreadChatMessages.reduce((pv, cv) => pv + cv.count, 0);
    },

    hostUnreadChatMessageCount(): number {
      return this.status.unreadChatMessages.reduce((pv, cv) => pv + cv.count, 0);
    },
  },
  methods: {
    goBackToAdmin() {
      this.$store.dispatch('AuthStoreModule/backToAdmin').then(() => {
        this.$router.push('/');
      });
    },
    logout() {
      this.$store.dispatch('AuthStoreModule/logout').then(() => {
        this.$router.push('/');
      });
    },
  },
});
