































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import SelectDto from '@/models/SelectDto';

const validations = {
  rating: {},
  selfCheckIn: {},
  sauna: {},
  maxDistance: {},
};

export default Vue.extend({
  components: { ValidatedCheckbox },
  mixins: [validationMixin],
  validations,
  mounted() {
    this.loadData();
  },
  data() {
    return {
      rating: false,
      selfCheckIn: false,
      sauna: false,
      maxDistance: '',
      radiusValue: ['', '100', '60', '30', '15'],
    };
  },
  watch: {
    rating: {
      handler() {
        this.emitChange();
      },
    },
    selfCheckIn: {
      handler() {
        this.emitChange();
      },
    },
    sauna: {
      handler() {
        this.emitChange();
      },
    },
    maxDistance: {
      handler() {
        this.$store.commit('SearchStoreModule/setMaxDistance', this.maxDistance);
        this.emitChange();
      },
    },
    maxDistanceSearchParameter: {
      handler() {
        this.loadData();
      },
    },
    '$store.state.SearchStoreModule.searchParameter.userLocation.place': {
      deep: true,
      handler() {
        this.setMaxDistance();
      },
    },
  },
  computed: {
    radiusOptions(): SelectDto[] {
      return this.radiusValue.map((t) => {
        let translation = '';
        if (t == '') {
          translation = this.$t('enums.radius.Nolimits').toString();
        } else {
          translation = this.$t('enums.radius.' + t).toString();
        }
        return {
          value: t,
          label: translation,
        };
      });
    },
    isLocationEnter(): boolean {
      return this.$store.state.SearchStoreModule.searchParameter.userLocation.lat != 0;
    },
    maxDistanceSearchParameter(): string {
      return this.$store.state.SearchStoreModule.searchParameter.maxDistance;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
    loadData() {
      this.maxDistance = this.maxDistanceSearchParameter;
    },
    setMaxDistance() {
      const place = this.$store.state.SearchStoreModule.searchParameter.userLocation?.place;
      if (
        place?.address_components?.length == 1 &&
        place?.address_components[0].types.filter((f: any) => {
          return f == 'country';
        }).length > 0
      ) {
        this.maxDistance = '';
      } else if (place.address_components?.length > 1) {
        this.maxDistance = '100';
      }
    },
  },
});
