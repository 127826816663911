import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import SearchParameter from '@/models/SearchParameter';
import UserLocation from '@/models/UserLocation';

@Module({ name: 'SearchStoreModule', namespaced: true })
export default class SearchStoreModule extends VuexModule {
  searchParameter: SearchParameter = new SearchParameter();

  @Mutation
  saveSearchParameter(searchParameter: SearchParameter) {
    this.searchParameter = searchParameter;
  }

  @Mutation
  setGuestCount(guestCount: number) {
    this.searchParameter.guestCount = guestCount;
  }

  @Mutation
  setUserLocation(userLocation: UserLocation) {
    this.searchParameter.userLocation = userLocation;
  }

  @Mutation
  resetDates() {
    // Reset Time
    this.searchParameter.checkInDate = '';
    this.searchParameter.checkOutDate = '';
    this.searchParameter.checkOutTime = '';
    this.searchParameter.checkInTime = '';
    this.searchParameter.checkOutTimeValue = '';
    this.searchParameter.checkInTimeValue = '';
    this.searchParameter.checkInIsValid = true;
  }

  @Mutation
  resetFilters() {
    this.searchParameter.houseRules = [];
    this.searchParameter.bookingPolicies = [];
    this.searchParameter.propertyTypes = [];
    this.searchParameter.features = [];
    this.searchParameter.minRating = '0';
    this.searchParameter.maxDistance = '';
    this.searchParameter.onlyFavorites = false;
  }

  @Mutation
  setBookingType(bookingType: string) {
    this.searchParameter.bookingType = bookingType;
    // Reset Time
    this.searchParameter.checkInDate = '';
    this.searchParameter.checkOutDate = '';
    this.searchParameter.checkOutTime = '';
    this.searchParameter.checkInTime = '';
    this.searchParameter.checkOutTimeValue = '';
    this.searchParameter.checkInTimeValue = '';
    this.searchParameter.checkInIsValid = true;
  }

  @Mutation
  setDates(date: {
    checkInDate: string;
    checkOutDate: string;
    checkInTime: string;
    checkOutTime: string;
    checkInTimeValue: string;
    checkOutTimeValue: string;
    checkInIsValid: boolean;
  }) {
    this.searchParameter.checkInDate = date.checkInDate;
    this.searchParameter.checkOutDate = date.checkOutDate;
    if (date.checkInTime || date.checkInTimeValue || date.checkOutTimeValue) {
      this.searchParameter.checkInTime = date.checkInTime;
      this.searchParameter.checkInTimeValue = date.checkInTimeValue;
      this.searchParameter.checkOutTime = date.checkOutTime;
      this.searchParameter.checkOutTimeValue = date.checkOutTimeValue;
    }
    this.searchParameter.checkInIsValid = date.checkInIsValid;
  }

  @Mutation
  setMaxDistance(maxDistance: string) {
    this.searchParameter.maxDistance = maxDistance;
  }

  @Mutation
  reset() {
    this.searchParameter = new SearchParameter();
  }

  @Mutation
  setMinRating(minRating: string) {
    this.searchParameter.minRating = minRating;
  }

  @Mutation
  setPropertyTypes(propertyTypes: string[]) {
    this.searchParameter.propertyTypes = propertyTypes;
  }

  @Mutation
  setHouseRules(houseRules: string[]) {
    this.searchParameter.houseRules = houseRules;
  }

  @Mutation
  setFeatures(features: string[]) {
    this.searchParameter.features = features;
  }

  @Mutation
  setBookingPolicies(bookingPolicies: string[]) {
    this.searchParameter.bookingPolicies = bookingPolicies;
  }

  @Mutation
  setOnlyFavorites(onlyFavorites: boolean) {
    this.searchParameter.onlyFavorites = onlyFavorites;
  }
}
