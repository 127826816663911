





































































































import Vue from 'vue';
import DynamicBreadcrumb from '@/components/shared/DynamicBreadcrumb.vue';
import OfficeModeButton from '@/components/officeMode/OfficeModeButton.vue';
import { mapState } from 'vuex';
import LanguageSwitch from './LanguageSwitch.vue';

export default Vue.extend({
  components: { DynamicBreadcrumb, OfficeModeButton, LanguageSwitch },
  computed: {
    ...mapState(['breadCrumb']),
    isLoggedIn(): boolean {
      return this.$store.state.AuthStoreModule.status.loggedIn;
    },
    isProduction(): boolean {
      return process.env.VUE_APP_SHOW_NON_PRODUCTION_BANNER == 'false';
    },
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
    currentUser(): { role: string; langPreference: string } {
      return this.$store.state.AuthStoreModule.user;
    },
    canGoBackToAdmin(): boolean {
      return this.$store.state.AuthStoreModule.status.canGoBackToAdmin;
    },
    roleTranslation(): string {
      return this.$t('enums.userRoles.' + this.currentUser.role).toString();
    },
    maintenanceMode(): boolean {
      return process.env.VUE_APP_MAINTENANCE_MODE == 'true';
    },
  },
  methods: {
    goBackToAdmin() {
      this.$store.dispatch('AuthStoreModule/backToAdmin').then(() => {
        this.$router.push('/');
      });
    },
    logout() {
      this.$store.dispatch('AuthStoreModule/logout').then(() => {
        this.$router.push('/');
      });
    },
  },
});
