






































import Vue from 'vue';
import ValidatedInputErrors from './ValidatedInputErrors.vue';
import nanoid from 'nanoid';

class Option {
  label!: string;
  value!: number | string;
}

export default Vue.extend({
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    label: { type: String },
    placeholder: { type: String },
    description: { type: String },
    id: { type: String },
    translationKey: { type: String },
    options: {
      type: Array as () => Array<Option>,
    },
  },
  components: { ValidatedInputErrors },
  data() {
    return {
      randomId: '',
    };
  },
  mounted() {
    if (this.options && this.options.length > 0) {
      const values = this.options.map((o) => o.value);
      if (!values.includes(this.value)) {
        this.$emit('update:value', values[0]);
      }
    }
  },
  computed: {
    checkIfValid(): boolean | null {
      if (this.field === undefined || !this.field.$dirty) {
        return null;
      }
      if (this.field.$invalid) {
        return false;
      }
      if (this.field.$model === '' && this.isRequired) {
        return false;
      }
      return true;
    },
    shownLabel(): string {
      if (this.label !== undefined) {
        return this.label;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('label');
      } else {
        return this.label;
      }
    },
    name(): string {
      return this.translationKey.split('.').reverse()[0];
    },
    isRequired(): boolean {
      return this.field.required !== undefined;
    },
    shownDescription(): string {
      if (this.description !== undefined) {
        return this.description;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('description');
      } else {
        return this.description;
      }
    },
    shownPlaceholder(): string {
      if (this.placeholder !== undefined) {
        return this.placeholder;
      } else if (this.translationKey !== undefined) {
        return this.getTranslation('placeholder');
      } else {
        return this.placeholder;
      }
    },
  },
  methods: {
    getTranslation(key: string): string {
      const translationKey = 'forms.' + this.translationKey + '.' + key;
      return this.$te(translationKey) ? this.$t(translationKey).toString() : '';
    },
    safeId(): string {
      if (this.id === undefined) {
        if (this.randomId === '') {
          this.randomId = nanoid();
        }
        return this.randomId;
      } else {
        return this.id;
      }
    },
    onChange(event: { target: { value: string } }) {
      this.$emit('update:value', event?.target?.value);
    },
  },
});
