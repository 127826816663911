import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ApiService from '@/services/ApiService';

@Module
export default class BackendVersionStoreModule extends VuexModule {
  lastCheck = new Date(2020, 6, 28);
  version = 'N/A';

  @Mutation
  setVersion(version: string) {
    this.version = version;
    this.lastCheck = new Date();
  }

  @Action({ commit: 'setVersion' })
  async getBackendVersion() {
    const today = new Date();
    const diffMs = +today - +this.context.getters['lastCheck']; // milliseconds between now & Christmas
    const diffMins = Math.round(Math.abs(diffMs) / (1000 * 60));
    if (diffMins > 10) {
      const response = await ApiService.get(`/api/Public/VersionInfo/Index`);
      return response.value;
    }
  }
}
