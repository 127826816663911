import { helpers } from 'vuelidate/lib/validators';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';

const beforeCheckOut = helpers.withParams({ type: 'minDate' }, (value: string, object: { checkOutDate: string }) => {
  if (!object.checkOutDate || !value) {
    return true;
  }
  const checkIn = parse(value, 'yyyy-MM-dd', new Date());
  const checkOut = parse(object.checkOutDate, 'yyyy-MM-dd', new Date());
  return isBefore(checkIn, checkOut);
});

export default beforeCheckOut;
