








import Vue from 'vue';

export default Vue.extend({
  props: ['field', 'label'],
  computed: {
    hasError(): boolean {
      return !!this.field.$error;
    },
    errors(): string[] {
      const errors = [];
      for (const key in this.field) {
        if (!key.startsWith('$') && !this.field[key]) {
          errors.push(key);
        }
      }
      return errors;
    },
  },
  methods: {
    errorMessage(key: string) {
      const fieldName = this.label || this.$t('components.ValidatedInputErrors.thisField').toString();
      let value = '';
      switch (key) {
        case 'minValue':
          value = this.field.$params[key].min;
          break;
        case 'maxValue':
          value = this.field.$params[key].max;
          break;
        case 'minLength':
          value = this.field.$params[key].min;
          break;
        case 'maxLength':
          value = this.field.$params[key].max;
          break;
      }
      return this.$t('components.ValidatedInputErrors.' + key, { fieldName, value });
    },
  },
});
