import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/Status/';

class CustomerStatusService {
  getStatus() {
    return ApiService.get(`${API_URL}Get`);
  }
}

export default new CustomerStatusService();
