import { RouteConfig } from 'vue-router';

const CustomerOrderIndex = () => import('@/views/customer/orders/CustomerOrderIndex.vue');
const CustomerOrderDetail = () => import('@/views/customer/orders/CustomerOrderDetail.vue');
const CustomerMessageIndex = () => import('@/views/customer/messages/CustomerMessageIndex.vue');
const CustomerCreateMessage = () => import('@/views/customer/messages/CustomerCreateMessage.vue');
// const CustomerMessageDetail = () => import('@/views/customer/messages/CustomerMessageDetail.vue');
const AccountEdit = () => import('@/views/customer/account/AccountEdit.vue');
const CustomerDeleteAccount = () => import('@/views/customer/users/CustomerDeleteAccount.vue');

export default [
  {
    path: '/customers/orders',
    name: 'CustomerOrderIndex',
    component: CustomerOrderIndex,
  },
  {
    path: '/customers/orders/:orderId',
    name: 'CustomerOrderDetail',
    component: CustomerOrderDetail,
    props: true,
  },
  {
    path: '/customers/Messages',
    name: 'CustomerMessageIndex',
    component: CustomerMessageIndex,
    props: true,
  },
  {
    path: '/customer/messages/:openMessageId',
    name: 'CustomerMessageIndexWithOpenMessage',
    component: CustomerMessageIndex,
    props: true,
  },
  {
    path: '/customer/CreateMessage/:receiverId',
    name: 'CustomerCreateMessage',
    component: CustomerCreateMessage,
    props: true,
  },
  // {
  //   path: '/customer/messages/:messageId',
  //   name: 'CustomerMessageDetail',
  //   component: CustomerMessageDetail,
  //   props: true,
  // },
  {
    path: '/customer/account',
    name: 'CustomerAccountEdit',
    component: AccountEdit,
    props: true,
  },
  {
    path: '/customer/User/DeleteAccount',
    name: 'CustomerDeleteAccount',
    component: CustomerDeleteAccount,
  },
] as RouteConfig[];
