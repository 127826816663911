export default class ErrorHandler {
  te: (_key: string) => boolean;
  t: (_key: string, _values: {}) => string;

  constructor(te: (key: string) => boolean, t: (key: string, values: { [key: string]: string }) => string) {
    this.te = te;
    this.t = t;
  }

  handle(error: string) {
    const errors = error.split(', ');
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].startsWith('{')) {
        try {
          const json = JSON.parse(errors[i]);
          errors[i] = json.Name;
          const errorTranslationKey = 'errors.' + json.Name.toString();
          if (this.te(errorTranslationKey)) {
            const fieldName = this.t('forms.' + json.Values.translationKey + '.label', {});
            errors[i] = this.t(errorTranslationKey, { fieldName, ...json.Values });
          } else {
            errors[i] = 'Unknown Error: ' + json.Name;
          }
        } catch (ex: any) {
          errors[i] = ex.message;
        }
      }
    }
    return errors.join(', ');
  }
}
