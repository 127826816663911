import i18n from '@/i18n';
import ToastMessage from '@/models/ToastMessage';
import * as signalR from '@aspnet/signalr';
import store from '../store';

const SignalRPlugin = {
  install() {
    //Vue: Vue
    // make your signalR connection
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(process.env.VUE_APP_SIGNALR_URL, {
        accessTokenFactory: () => {
          const bearerToken = store.state.AuthStoreModule.user.token;
          return bearerToken;
        },
      })
      .build();

    connection.start().then(() => {
      store.commit('UiStoreModule/webSocketConnected');
    });

    connection.onclose(async () => {
      store.commit('UiStoreModule/webSocketDisconnected');
    });

    // register callbacks
    connection.on('SeedProgress', (text: string, step: number, totalSteps: number) => {
      store.commit('MaintainanceStoreModule/setSeedProgress', { text, step, totalSteps });
    });

    connection.on('GuestHasNewChatMessage', () => {
      const toastMessage = i18n.t('pages.customer.GuestChatMessageDetail.newMessageToastMessage').toString();
      store.commit('addToastMessage', new ToastMessage(toastMessage, 'success'));
    });

    connection.on('HostHasNewChatMessage', (guestId: string) => {
      store.commit('ChatStoreModule/newChatMessageFromGuestReceived', guestId);
      // Show toast Message
      const toastMessage = i18n.t('pages.host.HostChatMessageDetail.newMessageToastMessage').toString();
      store.commit('addToastMessage', new ToastMessage(toastMessage, 'success'));
      // Play sound
      const audio = new Audio('/sounds/pling.mp3');
      audio.play();
    });
    //Anything you want to expose outside of the plugin should be prefixed Vue.prototype.$
    // Vue.prototype.$sendSomething = function (something: any) {
    //   connection.send('Incoming', something);
    // };
  },
};

export default SignalRPlugin;
