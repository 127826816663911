






































import OrderDto from '@/models/OrderDto';
import Vue, { PropType, VueConstructor } from 'vue';
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/de.js';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import 'vue-cal/dist/vuecal.css';
import format from 'date-fns-tz/format';

class EventDto {
  start!: string;
  end!: string;
  title!: string;
  class!: string;
}
export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    orders: {
      type: Array as PropType<OrderDto[]>,
      required: true,
    },
    activeView: {
      type: String,
      default: 'month',
    },
    selectedDate: {
      type: Date,
      default() {
        return new Date();
      },
    },
  },
  components: { VueCal },
  methods: {
    buildTitle(order: OrderDto): string {
      let title = this.orderType(order);
      if (order.playroomName) {
        title += ': ' + order.playroomName;
      }
      return title;
    },

    orderType(order: OrderDto): string {
      let translationKey = 'enums.occupationType.' + order.type;
      if (order.type == 'Booking' && order.orderStatus == 'Pending') {
        translationKey += 'Unconfirmed';
      }
      return this.$t(translationKey).toString();
    },

    buildContent(order: OrderDto): string {
      var content = '';
      if (order.customerName) {
        content += 'Gast: ' + order.customerName;
      }
      if (order.icsName) {
        content += order.icsName;
      }
      return content;
    },

    buildClasses(order: OrderDto): string {
      let klass = order.type;
      if (order.type == 'Booking' && order.orderStatus == 'Pending') {
        klass += 'Unconfirmed';
      }
      return klass;
    },

    buildUrl(order: OrderDto): string {
      return order.type;
    },

    //isAllDay(start: string, end: string): boolean {
    //  return false;
    // TODO: what should be all day?
    //   const startDate = this.parseIsoDateTimeTz(start);
    //   const endDate = this.parseIsoDateTimeTz(end);
    //   console.log(startDate, endDate);
    //   if (startDate == null || endDate == null) {
    //     return false;
    //   }
    //   //console.log(this.differenceInMinutes(startDate, endDate));
    //   return this.differenceInMinutes(startDate, endDate) == 60 * 24;
    // },

    // differenceInMinutes(start: Date, end: Date): number {
    //   var diff = (end.getTime() - start.getTime()) / 1000;
    //   diff /= 60;
    //   return Math.abs(Math.round(diff));
    // },
    formatDate(date: Date | string): string {
      if (date instanceof Date) {
        return format(date, 'yyyy-MM-dd HH:mm');
      } else {
        return date.split('Z')[0].replace('T', ' ');
      }
    },
  },
  computed: {
    events(): EventDto[] {
      const events = this.orders.map((o) => {
        return {
          id: o.id,
          start: this.formatDate(o.startTimeInLocal),
          end: this.formatDate(o.endTimeInLocal),
          title: this.buildTitle(o),
          class: this.buildClasses(o),
          content: this.buildContent(o),
          url: this.buildUrl(o),
          allDay: false, //this.isAllDay(o.startTimeInLocal, o.endTimeInLocal),
        } as EventDto;
      });
      return events;
    },
  },
});
