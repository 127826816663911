








































































































































































import Vue from 'vue';
import OwnerOrderCalendarService from '@/services/owner/OwnerOrderCalendarService';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import DataTable from '@/components/shared/DataTable.vue';
import CalendarSync from './CalendarSync.vue';
import OwnerPlayroomTemporalModal from '@/views/owner/calendar/OwnerPlayroomTemporalModal.vue';
import { MetaInfo } from 'vue-meta';
import HostCalendar from '@/components/shared/HostCalendar.vue';
import OrderDto from '@/models/OrderDto';

class PlayroomWithTempCount {
  playroomId!: string;
  playroomName!: string;
  tempCount!: number;
}

class CalendarPlayroomDto {
  id!: string;
  name!: string;
  icsCalendarUrl!: string;
  icsCalendarLastUpdateAt!: string;
}

export default Vue.extend({
  components: { DataTable, OwnerPlayroomTemporalModal, CalendarSync, HostCalendar },
  mixins: [FormaterMixin],
  data() {
    return {
      orders: [] as OrderDto[],
      playroomWithTempCount: [] as PlayroomWithTempCount[],
      playrooms: [] as CalendarPlayroomDto[],
      showDate: new Date(),
      calendarToken: '',
      publicCalendarToken: '',
      tableFilterValue: '',
      sorterValue: { column: 'startTimeInUTC', asc: true },
      loading: false,
      showTempModal: false,
      playroomId: '',
      popupTitle: '',
      tempCount: 0,
      selectedPlayrooms: [] as string[],
      hideOldOrders: true,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData().then(() => {
      this.selectedPlayrooms = this.playrooms.map((p) => p.id);
    });
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostCalendar.meta.title').toString(),
    };
  },
  methods: {
    onPlayroomSelectionChange(event: Event) {
      if (event == null) {
        return;
      }
      const checkbox = event.target as HTMLInputElement;
      const playroomId = checkbox.dataset.playroomId;
      const selected = checkbox.checked;
      if (playroomId) {
        if (selected) {
          this.selectedPlayrooms.push(playroomId);
        } else {
          this.selectedPlayrooms = this.selectedPlayrooms.filter((p) => p != playroomId);
        }
      }
    },

    guestName(order: OrderDto): string {
      if (order.type == 'Booking') {
        return order.customerName;
      }
      return '';
    },

    orderType(order: OrderDto): string {
      let translationKey = 'enums.occupationType.' + order.type;
      if (order.type == 'Booking' && order.orderStatus == 'Pending') {
        translationKey += 'Unconfirmed';
      }
      return this.$t(translationKey).toString();
    },

    orderId(order: OrderDto): string {
      if (order.type == 'Booking') {
        return order.orderId.split('-')[0];
      }
      return '';
    },

    viewChange(startDate: Date) {
      const loadNewData =
        startDate == null || this.showDate == null || startDate.getMonth() != this.showDate.getMonth();
      this.showDate = startDate;
      if (loadNewData) {
        this.loadData();
      }
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostCalendar.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      const result = OwnerOrderCalendarService.getIndex(
        this.calendarStartDate,
        this.calendarEndDate,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.orders = res.value.events.map((e: OrderDto) => ({
          ...e,
          startTimeInUTC: e.startTimeInUTC,
          endTimeInUTC: e.endTimeInUTC,
          startTimeInLocal: e.startTimeInLocal,
          endTimeInLocal: e.endTimeInLocal,
        }));
        this.calendarToken = res.value.calendarToken;
        this.publicCalendarToken = res.value.publicCalendarToken;
        this.loading = false;
        this.playrooms = res.value.playrooms;
      });

      OwnerOrderCalendarService.playroomWithTempCount(
        new Date(this.showDate.getFullYear(), this.showDate.getMonth() - 1, 1),
        new Date(this.showDate.getFullYear(), this.showDate.getMonth() + 2, 1)
      ).then((res) => {
        this.playroomWithTempCount = res.value;
      });

      return result;
    },
    openTempModal(playroomWithTempCount: PlayroomWithTempCount) {
      this.playroomId = playroomWithTempCount.playroomId;
      this.tempCount = playroomWithTempCount.tempCount;
      this.popupTitle = this.$t('pages.owner.calendar.playroomTemporal.title', {
        playroomName: playroomWithTempCount.playroomName,
      }).toString();
      this.showTempModal = true;
    },
    onCloseModel() {
      this.showTempModal = false;
      this.loadData();
    },
    getNote(order: OrderDto) {
      if (order.unavailablePeriodNote) {
        return order.unavailablePeriodNote;
      }
      if (order.icsName) {
        return order.icsName;
      }
      if (order.type == 'GeneralAvailability') {
        return this.$t('pages.owner.calendar.notes.GeneralAvailabilityNote');
      }
      return this.$t('pages.owner.calendar.TemporalAvailabilty.noNote');
    },
  },
  computed: {
    calendarStartDate(): Date {
      return new Date(this.showDate.getFullYear(), this.showDate.getMonth() - 1, 1);
    },

    calendarEndDate(): Date {
      return new Date(this.showDate.getFullYear(), this.showDate.getMonth() + 2, 1);
    },

    ordersForTable(): OrderDto[] {
      let orders = this.selectedOrders.filter(
        (order) => order.type != 'NotAllowedOvernight' && order.type != 'NotAllowedHourly'
      );
      // filter orders in the past
      if (this.hideOldOrders) {
        const currentDate = new Date().toISOString();
        orders = orders.filter((order) => order.endTimeInLocal > currentDate);
      }
      return orders;
    },

    selectedOrders(): OrderDto[] {
      return this.orders.filter((order) => this.selectedPlayrooms.includes(order.playroomId));
    },
  },
});
