import ApiService from '@/services/ApiService';

const API_URL = '/api/user/nextActivities/';

class NextActivityService {
  getHostData() {
    return ApiService.get(`${API_URL}HostNextActivityData`);
  }
}
export default new NextActivityService();
