



















































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import SameOrAfterCurrentDateValidator from '@/validators/SameOrAfterCurrentDateValidator';
import { addDays, format, parse } from 'date-fns';

const validations = {
  bookingDate: {
    SameOrAfterCurrentDateValidator,
  },
  checkInTime: {},
  checkOutTime: {},
};

export default Vue.extend({
  components: { ValidatedSelect, ValidatedInput },
  mixins: [validationMixin],
  validations,
  data() {
    const searchParameter = this.$store.state.SearchStoreModule.searchParameter;
    return {
      bookingDate: searchParameter.checkInDate,
      checkInTime: searchParameter.checkInTimeValue,
      checkOutTime: searchParameter.checkOutTimeValue,
      times: [
        { label: '00:00', value: '0' },
        { label: '01:00', value: '1' },
        { label: '02:00', value: '2' },
        { label: '03:00', value: '3' },
        { label: '04:00', value: '4' },
        { label: '05:00', value: '5' },
        { label: '06:00', value: '6' },
        { label: '07:00', value: '7' },
        { label: '08:00', value: '8' },
        { label: '09:00', value: '9' },
        { label: '10:00', value: '10' },
        { label: '11:00', value: '11' },
        { label: '12:00', value: '12' },
        { label: '13:00', value: '13' },
        { label: '14:00', value: '14' },
        { label: '15:00', value: '15' },
        { label: '16:00', value: '16' },
        { label: '17:00', value: '17' },
        { label: '18:00', value: '18' },
        { label: '19:00', value: '19' },
        { label: '20:00', value: '20' },
        { label: '21:00', value: '21' },
        { label: '22:00', value: '22' },
        { label: '23:00', value: '23' },
      ],
    };
  },
  watch: {
    checkOutTime() {
      this.saveChange();
    },
    bookingDate() {
      if (!this.bookingDate) {
        this.checkInTime = '';
        this.checkOutTime = '';
      } else if (!this.checkInTime) {
        this.checkInTime = '12';
        this.checkOutTime = '13';
      }
      this.saveChange();
    },
    checkInTime() {
      this.$nextTick(() => {
        if (this.checkInTime == '') {
          this.checkOutTime = '';
        } else {
          this.checkOutTime = this.checkOutTimes.map((x) => x.value)[0];
        }
      });
      this.$v.$touch();
      this.saveChange();
    },
    savedCheckInDate() {
      this.bookingDate = this.savedCheckInDate;
    },
    savedCheckInTime() {
      this.checkInTime = this.savedCheckInTime;
    },
    savedCheckOutTime() {
      this.checkOutTime = this.savedCheckOutTime;
    },
  },
  computed: {
    bookingType(): string {
      return this.$store.state.SearchStoreModule.searchParameter.bookingType;
    },
    checkInTimeOptions(): { value: string; label: string }[] {
      if (this.checkInTimeIsDisabled) {
        return [
          {
            value: '',
            label: this.$t('pages.playroomSearch.notSpecified').toString(),
          },
        ];
      } else {
        return this.times.map((t) => ({
          value: t.value,
          label: t.label,
        }));
      }
    },
    checkInTimeIsDisabled(): boolean {
      return !this.bookingDate;
    },
    checkOutTimeIsDisabled(): boolean {
      return !this.checkInTime || !this.bookingDate;
    },
    checkOutTimes(): { value: string; label: string }[] {
      // if checkInTime is empty, checkOut is empty
      if (this.checkInTime === '') return this.checkInTimeOptions.filter((x) => x.value == '');
      let timeToRange = [];
      // Start with the next number after the current time
      let timeCounter = Number(this.checkInTime);
      let postFix = '';
      for (let index = 0; index < 24; index++) {
        timeCounter++;
        if (timeCounter > 23) {
          timeCounter = 0;
          postFix = this.$t('pages.playroomSearch.hourlyBookingNextDay').toString();
        }
        const obj = { ...this.checkInTimeOptions[timeCounter] };
        obj.label = obj.label + postFix;
        timeToRange.push(obj);
      }
      return timeToRange;
    },
    savedCheckInDate(): string {
      return this.$store.state.SearchStoreModule.searchParameter.checkInDate;
    },
    savedCheckInTime(): string {
      return this.$store.state.SearchStoreModule.searchParameter.checkInTimeValue;
    },
    savedCheckOutTime(): string {
      return this.$store.state.SearchStoreModule.searchParameter.checkOutTimeValue;
    },
  },
  methods: {
    clearData() {
      this.checkInTime = '';
      this.checkOutTime = '';
      this.bookingDate = '';
      this.$nextTick(() => this.$v.$reset());
    },

    formatTime(number: string): string {
      if (number === undefined || number == '') {
        return '';
      }
      if (number.length == 1) {
        return `0${number}:00`;
      } else if (number.length == 2) {
        return `${number}:00`;
      } else {
        return number;
      }
    },

    saveChange() {
      if (this.bookingType === 'hourly') {
        let checkOutDate = this.bookingDate;
        const checkInTime = Number(this.checkInTime);
        const checkOutTime = Number(this.checkOutTime);
        if (checkOutDate != '' && checkOutDate !== undefined && checkInTime >= checkOutTime) {
          const formatString = 'yyyy-MM-dd';
          let date = parse(this.bookingDate, formatString, new Date(), {});
          date = addDays(date, 1);
          checkOutDate = format(date, formatString);
        }
        if (checkOutDate == '') {
          this.$store.commit('SearchStoreModule/resetDates');
        } else {
          this.$store.commit('SearchStoreModule/setDates', {
            checkInDate: this.bookingDate,
            checkOutDate: checkOutDate,
            checkInTime: this.formatTime(this.checkInTime),
            checkOutTime: this.formatTime(this.checkOutTime),
            checkInTimeValue: this.checkInTime,
            checkOutTimeValue: this.checkOutTime,
            checkInIsValid: !this.$v.$anyError,
          });
        }
      }
    },
  },
});
