














import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';

const validations = {
  bookingPolicies: {
    $each: {
      name: {},
      hasFilter: {},
    },
  },
};

class BookingPoliciesFilterModel {
  name!: string;
  hasFilter!: boolean;
}

export default Vue.extend({
  components: { ValidatedCheckbox },
  mixins: [validationMixin],
  validations,
  props: {
    bookingPolicies: {
      type: Array as PropType<BookingPoliciesFilterModel[]>,
      required: true,
    },
    showFreeCancellation: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    bookingPolicies: {
      deep: true,
      handler() {
        this.emitChange();
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change', this.bookingPolicies);
    },

    showFilter(bookingPolicy: any): boolean {
      if (bookingPolicy.name.$model === 'freeCancellation') {
        return this.showFreeCancellation;
      }
      return true;
    },
  },
});
