import AuthService from '@/services/AuthService';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import User from '@/models/user';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Module as Mod } from 'vuex';
const savedUser = Vue.$cookies.get('user') as User;
import StatusUpdater from '@/services/StatusUpdater';
import { event, set } from 'vue-gtag';

const setNameInTracker = (user: User, isAdmin: boolean) => {
  const windowObject = window;
  if ((windowObject as any)?.$zohosq) {
    let displayName = user.displayName;
    if (isAdmin) {
      displayName = '(Admin) ' + displayName;
      event('login', { method: 'AsAdmin' });
    } else {
      event('login', { method: 'Direct' });
    }
    (windowObject as any).$zohosq.visitor.name(displayName);
    (windowObject as any).$zohosq.visitor.email(user.userName);
    (windowObject as any).$zohosq.visitor.uniqueuserid(user.id);
    (windowObject as any).$zoho.salesiq.visitor.info({ userId: user.id, isAdmin: isAdmin });
    set({ dimension1: user.role });
  }
};

@Module({ namespaced: true })
export default class AuthStoreModule extends VuexModule {
  public status = { loggedIn: false, canGoBackToAdmin: false };
  public user: User | undefined;
  constructor(module: Mod<ThisType<AuthStoreModule>, any>) {
    super(module);
    this.status.canGoBackToAdmin = Vue.$cookies.get('oldUser') != null;
    if (savedUser) {
      this.user = savedUser;
      this.status.loggedIn = true;
      setNameInTracker(this.user, this.status.canGoBackToAdmin);
    } else {
      this.user = undefined;
      this.status.loggedIn = false;
    }
  }

  @Action({ rawError: true })
  login(user: User) {
    return AuthService.login(user).then(
      (returnedUser) => {
        this.context.commit('loginSuccess', returnedUser);
        this.status.canGoBackToAdmin = Vue.$cookies.get('oldUser') != null;
        setNameInTracker(returnedUser, this.status.canGoBackToAdmin);
        StatusUpdater.loadData();
        return Promise.resolve(returnedUser);
      },
      (error) => {
        this.context.commit('loginFailure');
        return Promise.reject(error);
      }
    );
  }

  @Action
  async loginAs(username: string) {
    await AuthService.loginAs(username).then((res) => {
      if (res.value.token) {
        this.context.commit('loginAsSuccess', res.value);
        StatusUpdater.loadData();
      }
    });
  }

  @Action({ commit: 'backToAdminMutation' })
  backToAdmin() {
    return AuthService.backToAdmin();
  }

  @Action({ commit: 'logoutMutation' })
  logout() {
    AuthService.logout();
  }

  @Action
  register(user: User) {
    return AuthService.register(user).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  @Mutation
  loginSuccess(user: User) {
    this.status.loggedIn = true;
    this.user = user;
  }

  @Mutation
  backToAdminMutation(user: User) {
    this.status.loggedIn = true;
    this.status.canGoBackToAdmin = false;
    this.user = user;
  }

  @Mutation
  loginAsSuccess(user: User) {
    this.status.loggedIn = true;
    this.status.canGoBackToAdmin = true;
    this.user = user;
  }

  @Mutation
  loginFailure() {
    this.status.loggedIn = false;
    this.user = undefined;
  }

  @Mutation
  logoutMutation() {
    this.status.loggedIn = false;
    this.user = undefined;
  }
}
