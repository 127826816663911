import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

class StatusChatMessage {
  guestId!: string;
  count!: number;
}

export class Status {
  unreadMessageCount = 0;
  unreadChatMessages: Array<StatusChatMessage> = new Array<StatusChatMessage>();
  newOrderCount = 0;
  subscriptedModules: Array<string> = new Array<string>();
}

@Module({ namespaced: true })
export default class StatusStoreModule extends VuexModule {
  status = new Status();

  @Mutation
  setCustomerStatus(customerStatus: Status) {
    this.status = customerStatus;
  }

  @Mutation
  setAdminStatus(adminStatus: Status) {
    this.status = adminStatus;
  }

  @Mutation
  setOwnerStatus(hostStatus: Status) {
    this.status = hostStatus;
  }

  @Mutation
  resetUnreadChatMessages(guestId: string) {
    const m = this.status.unreadChatMessages.find((cm: StatusChatMessage) => cm.guestId == guestId);
    if (m) {
      m.count = 0;
    }
  }
}
