








import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      bookingTypes: ['', 'hourly', 'overnight'],
      bookingType: this.$store.state.SearchStoreModule.searchParameter.bookingType,
    };
  },
  computed: {
    bookingTypeOptions(): { value: string; label: string }[] {
      return this.bookingTypes.map((t) => {
        const translation = t == '' ? 'notSpecified' : t;
        return {
          value: t,
          label: this.$t('enums.bookingType.' + translation).toString(),
          'data-testid': 'bookingType.' + translation,
        };
      });
    },
  },
  watch: {
    bookingType() {
      this.$store.commit('SearchStoreModule/setBookingType', this.bookingType);
    },
  },
});
