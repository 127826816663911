










import Vue from 'vue';
import ToastMessage from '@/models/ToastMessage';

export default Vue.extend({
  computed: {
    toasts(): ToastMessage[] {
      return this.$store.state.ToasterStoreModule.toasts;
    },
  },
});
