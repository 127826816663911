import ApiService, { ServerResponse } from '@/services/ApiService';

const API_URL = '/api/User/Account/';

class AccountService {
  updateEmailSettings(emailSettings: {
    allowEmailForBookings: string | boolean;
    allowEmailForPersonalOffers: string | boolean;
    allowEmailForFeedback: string | boolean;
  }) {
    emailSettings.allowEmailForBookings = String(emailSettings.allowEmailForBookings);
    emailSettings.allowEmailForPersonalOffers = String(emailSettings.allowEmailForPersonalOffers);
    emailSettings.allowEmailForFeedback = String(emailSettings.allowEmailForFeedback);
    return ApiService.post(`${API_URL}UpdateEmailSettings`, emailSettings);
  }
  getEmailSettings(): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EmailSettings`);
  }

  getLangPreference(): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}GetLangPreference`);
  }

  updateLangPreference(userLangPreference: { langPreference: string }) {
    return ApiService.post(`${API_URL}UpdateLangPreference`, userLangPreference);
  }
}

export default new AccountService();
