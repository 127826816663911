

























import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  data() {
    return {
      addClasses: '',
      addLinkClasses: '',
      addLastItemClasses: '',
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapState('UiStoreModule', ['breadcrumbItems']),
    items(): { to: string; text: string; addClasses: string }[] {
      return this.breadcrumbItems;
    },
    linkItems(): { to: string; text: string; addClasses: string }[] {
      return this.items.length > 0 ? this.items.slice(0, -1) : [];
    },
    lastItem(): { to: string; text: string; addClasses: string } | null {
      return this.items.length > 0 ? this.items[this.items.length - 1] : null;
    },
    sharedClasses(): string[] {
      return [this.addClasses, 'breadcrumb-item'];
    },
    lastItemClasses(): string[] {
      return ['active', this.lastItem?.addClasses, this.sharedClasses, this.addLastItemClasses].filter(
        (x) => x !== undefined
      ) as string[];
    },
  },
});
