

















import { NextActivity } from '@/store/modules/NextActivitiesStoreModule';
import Vue, { VueConstructor } from 'vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { mapState } from 'vuex';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  computed: {
    ...mapState('NextActivitiesStoreModule', ['nextActivities']),

    nextActableActivities(): NextActivity[] {
      return this.nextActivities.filter((na: NextActivity) => na.category == 'Actable');
    },

    nextCalendarActivities(): NextActivity[] {
      return this.nextActivities.filter((na: NextActivity) => na.category == 'Calendar');
    },
  },

  methods: {
    linkUrl(nextActivitiy: NextActivity) {
      switch (nextActivitiy.type) {
        case 'NextCheckIn':
        case 'NextCheckOut':
        case 'NewOrders': {
          return this.$router.resolve({ name: 'OwnerOrderDetail', params: { orderId: nextActivitiy.nextEntityId } })
            .href;
        }
        case 'UnreadMessages': {
          return this.$router.resolve({ name: 'OwnerMessageDetail', params: { messageId: nextActivitiy.nextEntityId } })
            .href;
        }
        case 'CreateFirstPlayroom': {
          return this.$router.resolve({ name: 'OwnerPlayroomIndex' }).href;
        }
        case 'UnreadChatMessages': {
          return this.$router.resolve({
            name: 'HostChatMessageDetail',
            params: { guestId: nextActivitiy.nextEntityId },
          }).href;
        }
      }
      return 'http://google.com/' + nextActivitiy.nextEntityId;
    },

    actableActivityText(nextActivity: NextActivity) {
      return this.$tc('components.NextActivities.typeMessages.' + nextActivity.type, nextActivity.count, {
        linkText: nextActivity.nextEntityText,
        linkUrl: this.linkUrl(nextActivity),
      });
    },

    calendarActivityText(nextActivity: NextActivity) {
      const start = this.formatIsoDateTimeTz(nextActivity.start);
      const startDistance = this.formatIsoDateTimeDistanceTz(nextActivity.start);
      const endDistance = this.formatIsoDateTimeDistanceTz(nextActivity.end);
      const end = this.formatIsoDateTimeTz(nextActivity.end);
      return this.$t('components.NextActivities.typeMessages.' + nextActivity.type, {
        linkText: nextActivity.nextEntityText,
        start,
        startDistance,
        endDistance,
        end,
        playroomName: nextActivity.playroomName,
        guestName: nextActivity.guestName,
        linkUrl: this.linkUrl(nextActivity),
      });
    },
  },
});
