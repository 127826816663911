



























import CookieLaw from 'vue-cookie-law';
import Vue from 'vue';
import SupportModal from '@/components/shared/SupportModal.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  components: { SupportModal, CookieLaw },
  data() {
    return {
      enviroment_infos: '',
      showSupportModal: false,
    };
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_SHOW_NON_PRODUCTION_BANNER == 'false';
    },
    ...mapState('UiStoreModule', ['webSocketStatus']),
    version(): string {
      return process.env.VUE_APP_VERSION;
    },
    backendVersion(): string {
      return this.$store.state.BackendVersionStoreModule.version;
    },
    businessTermsFileName(): string {
      const loggedIn = this.$store.state.AuthStoreModule.status.loggedIn;
      if (!loggedIn) return 'Nutzungsbedingung-Gast.pdf';
      var role = this.$store.state.AuthStoreModule.user.role;
      if (role == 'Customer') {
        return 'Nutzungsbedingung-Gast.pdf';
      } else {
        return 'Nutzungsbedingung-Gastgeber.pdf';
      }
    },
  },
  mounted() {
    this.$store.dispatch('getBackendVersion');
    //this.enviroment_infos = process.env.VUE_APP_API_BASE_URL + ' / ' + process.env.NODE_ENV;
  },
});
