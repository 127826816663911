import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/User/';

class AdminUserService {
  convertToGutshof(userId: string) {
    return ApiService.post(`${API_URL}ConvertToGutshof`, { userId });
  }

  convertOrdersToGutshof(userId: string) {
    return ApiService.post(`${API_URL}ConvertOrdersToGutshof`, { userId });
  }

  confirmUserEmail(userId: string) {
    return ApiService.post(`${API_URL}ConfirmUserEmail`, { userId });
  }

  recreateInvoices(hostUserId: string, date: string) {
    return ApiService.post(`${API_URL}RecreateInvoice`, { hostUserId, date });
  }

  recreateAllInvoices(hostUserId: string) {
    return ApiService.post(`${API_URL}RecreateAllInvoices`, { hostUserId });
  }

  downloadInvoice(hostUserId: string, date: string) {
    return ApiService.get(`${API_URL}Invoice?hostUserId=${hostUserId}&date=${date}`);
  }

  changePassword(userName: string, newPassword: string) {
    return ApiService.post(`${API_URL}ChangePassword?userName=${userName}`, { newPassword });
  }

  getCustomer(customerUserId: string) {
    return ApiService.get(`${API_URL}Customer?customerUserId=${customerUserId}`);
  }

  getHost(hostUserId: string) {
    return ApiService.get(`${API_URL}Host?hostUserId=${hostUserId}`);
  }

  getOwnerIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}OwnerIndex?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }

  delete(id: string) {
    return ApiService.delete(`${API_URL}Delete?userId=${id}`);
  }

  loginAs(userName: any) {
    return ApiService.post(`${API_URL}loginAs`, { userName });
  }

  getCustomerIndex(
    currentPage: number,
    sortColumn: string,
    sortAsc: boolean,
    tableFilter: string,
    showOnlyImportedGuests: boolean
  ) {
    return ApiService.get(
      `${API_URL}CustomerIndex?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}&showOnlyImportedGuests=${showOnlyImportedGuests}`
    );
  }
}

export default new AdminUserService();
