import axios, { AxiosResponse } from 'axios';
import authHeader from '@/services/auth-header';
import store from '@/store/index';
import ToastMessage from '@/models/ToastMessage';
import ErrorHandler from './ErrorHandler';
import i18n from '@/i18n';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export class ServerResponse {
  value!: any;
  error!: string;
  success!: boolean;
}
export class GenericServerResponse<T> {
  value!: T;
  error!: string;
  success!: boolean;
}
class ApiService {
  internalErrorHandler: ErrorHandler;

  get config() {
    return {
      headers: authHeader(),
    };
  }

  constructor() {
    this.internalErrorHandler = new ErrorHandler(
      (key: string) => i18n.te(key),
      (key: string, values: { [key: string]: string }) => i18n.t(key, values).toString()
    );
  }
  getBaseUrl(): string {
    return baseUrl;
  }

  getRaw(path: string): Promise<any> {
    return axios.get(`${baseUrl}${path}`, this.config);
  }

  get(path: string): Promise<ServerResponse> {
    return axios.get(`${baseUrl}${path}`, this.config).then((res) => {
      return this.successHandler(res);
    }, this.errorHandler);
  }

  delete(path: string): Promise<ServerResponse> {
    return axios.delete(`${baseUrl}${path}`, this.config).then((res) => {
      return this.successHandler(res);
    }, this.errorHandler);
  }

  post(path: string, data: {}, additionalHeaders = {}): Promise<ServerResponse> {
    const mergedConfig = { ...this.config, headers: { ...this.config.headers, ...additionalHeaders } };
    return axios.post(`${baseUrl}${path}`, data, mergedConfig).then((res) => {
      return this.successHandler(res);
    }, this.errorHandler);
  }

  private successHandler(res: AxiosResponse<any>): Promise<ServerResponse> {
    if (res.data === undefined) {
      return this.errorHandler({ message: 'No Data' });
    }
    if (!res.data.success) {
      return this.errorHandler(res.data.error);
    }
    return Promise.resolve(res.data);
  }

  private errorHandler(error: { message: string } | string): Promise<ServerResponse> {
    let message = 'N/A';
    if (error == undefined) {
      message = 'Unbekannter Fehler';
    } else if (error.constructor == Object) {
      message = (<{ message: string }>error).message;
    } else if (error.constructor == String) {
      message = this.internalErrorHandler.handle(error);
    } else if (error.constructor instanceof Function) {
      message = (<{ message: string }>error).message;
    }
    if (message == 'Request failed with status code 403') {
      message = 'Zugriff verweigert';
    }
    if (message == 'Request failed with status code 401') {
      message = 'Login benötigt';
    }
    if (message == 'Network Error') {
      message = 'Verbindung zum Server fehlgeschlagen';
    }
    store.commit('addToastMessage', new ToastMessage(`Fehler: ${message}`, 'bg-warning'));
    return Promise.reject(message);
  }
}

export default new ApiService();
