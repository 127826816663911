













import Vue from 'vue';

export default Vue.extend({
  computed: {
    officeModeButtonText(): string {
      return this.isDarkMode
        ? this.$t('components.OfficeModeButton.on').toString()
        : this.$t('components.OfficeModeButton.off').toString();
    },
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
  },
});
