import SearchParameter from '@/models/SearchParameter';
import ApiService from '@/services/ApiService';
import SearchStoreModule from '@/store/modules/SearchStoreModule';

const API_URL = '/api/Public/Playrooms/';

class PublicPlayroomService {
  getQrCodeUrl(playroomId: string): string {
    return `${process.env.VUE_APP_API_BASE_URL}${API_URL}GetQrCode?playroomId=${playroomId}`;
  }

  getFavorites() {
    return ApiService.get(`${API_URL}GetFavorites`);
  }
  getForBooking(playroomId: string, bookingType: string) {
    return ApiService.get(`${API_URL}Booking?playroomId=${playroomId}&bookingType=${bookingType}`);
  }

  getBookingWithCheckin(playroomId: string, checkIn: string, bookingType: string) {
    return ApiService.get(`${API_URL}Booking?playroomId=${playroomId}&checkIn=${checkIn}&bookingType=${bookingType}`);
  }

  getFeatures(id: string) {
    return ApiService.get(`${API_URL}Features?playroomId=${id}`);
  }

  getImages(id: string) {
    return ApiService.get(`${API_URL}Images?playroomId=${id}`);
  }

  getQuestions(id: string) {
    return ApiService.get(`${API_URL}Questions?playroomId=${id}`);
  }

  getDetailFromCustomUrl(playroomUrlPath: string) {
    return ApiService.get(`${API_URL}CustomUrl?&playroomUrlPath=${playroomUrlPath}`);
  }

  getIndex(onlyFavorites: boolean) {
    return ApiService.get(`${API_URL}Index?currentPage=1&onlyFavorites=${onlyFavorites}`);
  }

  getIndexWithPost(searchParameter: SearchParameter, store: SearchStoreModule) {
    const searchObject = { ...searchParameter } as any;
    searchObject.userLocation = searchParameter.userLocation.value;
    searchObject.onlyFavorites = searchParameter.onlyFavorites.toString();
    searchObject.latitude = searchParameter.userLocation.lat?.toString();
    searchObject.longitude = searchParameter.userLocation.lng?.toString();
    searchObject.maxDistance = searchParameter.maxDistance;
    searchObject.minRating = searchParameter.minRating.toString();
    searchObject.currentPage = searchParameter.currentPage.toString();
    searchObject.countOfGuests = store.searchParameter.guestCount.toString();
    searchObject.bookingType = store.searchParameter.bookingType.toString();

    searchObject.checkIn = store.searchParameter.checkInDate;
    if (store.searchParameter.checkInTime) {
      searchObject.checkIn += ' ' + store.searchParameter.checkInTime;
    }
    searchObject.checkOut = store.searchParameter.checkOutDate;
    if (store.searchParameter.checkOutTime) {
      searchObject.checkOut += ' ' + store.searchParameter.checkOutTime;
    }
    return ApiService.post(`${API_URL}Index`, searchObject);
  }

  getDetailPage(playroomId: string, pageId = '') {
    return ApiService.get(`${API_URL}PlayroomPage?playroomId=${playroomId}&playroomPageId=${pageId}`);
  }

  getDetail(playroomId: string) {
    return ApiService.get(`${API_URL}Playroom?playroomId=${playroomId}`);
  }

  getPrice(playroomId: string, bookingType: string) {
    return ApiService.get(`${API_URL}GetPrice?playroomId=${playroomId}&bookingType=${bookingType}`);
  }

  getFeatureList() {
    return ApiService.get(`${API_URL}FeatureList`);
  }
}

export default new PublicPlayroomService();
