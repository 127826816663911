


















































































import Vue from 'vue';
import VueGeolocation from 'vue-browser-geolocation';
import HourlyBookingType from '@/components/playroomSearch/HourlyBookingType.vue';
import OvernightBookingType from '@/components/playroomSearch/OvernightBookingType.vue';
import GuestCountFilter from '@/components/playroomSearch/GuestCountFilter.vue';
import PopularFilter from '@/components/playroomSearch/PopularFilter.vue';
import FavoriteFilter from '@/components/playroomSearch/FavoriteFilter.vue';
import RatingFilter from '@/components/playroomSearch/RatingFilter.vue';
//import PropertyTypeFilter from '@/components/playroomSearch/PropertyTypeFilter.vue';
import HouseRulesFilter from '@/components/playroomSearch/HouseRulesFilter.vue';
import BookingPolicyFilter from '@/components/playroomSearch/BookingPolicyFilter.vue';
import FeatureFilter from '@/components/playroomSearch/FeatureFilter.vue';
import { SaveStatusType } from '@/enums/SaveStatusType';
import AutocompleteLocation from '@/components/playroomSearch/AutocompleteLocation.vue';
import SearchParameter from '@/models/SearchParameter';
import BookingTypeSelector from '@/components/playroomSearch/BookingTypeSelector.vue';

Vue.use(VueGeolocation);

export default Vue.extend({
  components: {
    HourlyBookingType,
    OvernightBookingType,
    GuestCountFilter,
    AutocompleteLocation,
    BookingTypeSelector,
    PopularFilter,
    FavoriteFilter,
    RatingFilter,
    //PropertyTypeFilter,
    HouseRulesFilter,
    BookingPolicyFilter,
    FeatureFilter,
  },
  props: {
    withFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      guestCountSaveStatus: SaveStatusType.Valid,
      isOvernightBookingTypeFormValid: true,
    };
  },
  computed: {
    searchFiltersAreValid(): boolean {
      const bookingType = this.$store.state.SearchStoreModule.searchParameter.bookingType;
      let checkInIsValid = true;
      if (bookingType === 'overnight' || bookingType === 'hourly') {
        checkInIsValid = this.$store.state.SearchStoreModule.searchParameter.checkInIsValid;
      }
      const isValid = checkInIsValid && this.guestCountSaveStatus == SaveStatusType.Valid;
      return isValid;
    },
    searchParameter(): SearchParameter {
      return this.$store.state.SearchStoreModule.searchParameter;
    },
  },
  methods: {
    onRatingChange() {
      this.emitSearchParameter();
    },

    onFilterReset() {
      this.$store.commit('SearchStoreModule/resetFilters');
      this.emitSearchParameter();
    },

    onFavoriteChange() {
      this.emitSearchParameter();
    },

    onOvernightBookingTypeViewModelChange(isValid: boolean) {
      this.isOvernightBookingTypeFormValid = isValid;
    },

    onGuestCountStatusChange(guestCountSaveStatus: SaveStatusType) {
      this.guestCountSaveStatus = guestCountSaveStatus;
    },

    onHouseRulesChange() {
      this.emitSearchParameter();
    },

    onBookingPolicyFilterChange() {
      this.emitSearchParameter();
    },

    onFeatureFilterChange() {
      this.emitSearchParameter();
    },

    onPropertyTypeFilter() {
      this.emitSearchParameter();
    },

    onPopularFilter() {
      this.emitSearchParameter();
    },

    handleSearch() {
      this.$nextTick(() => {
        this.emitSearchParameter();
      });
    },

    emitSearchParameter() {
      this.searchParameter.maxDistance = this.$store.state.SearchStoreModule.searchParameter.maxDistance;
      this.$emit('searchParameterChange', this.searchParameter);
    },
  },

  watch: {
    maxDistance: function (newValue: number, oldValue: number) {
      if (oldValue !== newValue) {
        this.emitSearchParameter();
      }
    },
    onlyFavorites: function (newValue: boolean, oldValue: boolean) {
      if (oldValue !== newValue) {
        this.emitSearchParameter();
      }
    },
  },
});
