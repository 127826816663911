import { startOfToday, isAfter, isSameSecond, parse } from 'date-fns';
import { helpers } from 'vuelidate/lib/validators';

const minDate = helpers.withParams({ type: 'minDate' }, (value: string) => {
  if (!value) {
    return true;
  }
  const currentDate = startOfToday();
  const inputDate = parse(value, 'yyyy-MM-dd', new Date());
  return isAfter(inputDate, currentDate) || isSameSecond(inputDate, currentDate);
});
export default minDate;
