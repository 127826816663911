



























import Vue from 'vue';
import Place from '@/models/Place';
import UserLocation from '@/models/UserLocation';

export default Vue.extend({
  data() {
    return {
      userLocation: new UserLocation(),
      locSearch: '',
      addEventListener: {} as any,
    };
  },
  mounted() {
    this.userLocation = this.$store.state.SearchStoreModule.searchParameter.userLocation;
    const element: any = this.$refs.input as HTMLInputElement;
    element.value = this.userLocation.value;

    this.addEventListener = element.addEventListener ? element.addEventListener : element.attachEvent;
  },
  watch: {
    locSearch() {
      if (this.locSearch == '') {
        const userLocation = new UserLocation();
        userLocation.place = {} as Place;
        this.$store.commit('SearchStoreModule/setUserLocation', userLocation);
        this.$emit('update:userLocation', userLocation);
      }
    },
  },
  methods: {
    setAutocompletePlace(place: Place) {
      if (place.geometry == undefined) {
        return;
      }
      const userLocation = new UserLocation();
      userLocation.lat = place.geometry.location.lat();
      userLocation.lng = place.geometry.location.lng();
      userLocation.place = place;
      const element = this.$refs.input as HTMLInputElement;
      userLocation.value = element.value;
      this.$store.commit('SearchStoreModule/setUserLocation', userLocation);
      this.$emit('update:userLocation', userLocation);
    },
    inputChange() {
      const element: any = this.$refs.input as HTMLInputElement;
      var event: any = new KeyboardEvent('keydown', {
        keyCode: 40,
        which: 40,
      } as any);
      element.dispatchEvent(event);

      //setTimeout(function () {}, 100);
    },
  },
});
