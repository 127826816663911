import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Status/';

class OwnerStatusService {
  getStatus() {
    return ApiService.get(`${API_URL}Get`);
  }
}

export default new OwnerStatusService();
