import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'MaintainanceStoreModule', namespaced: true })
export default class AlertStoreModule extends VuexModule {
  seedProgressStep: number = 0;
  seedProgressTotalSteps: number = 0;
  seedProgressText: string = 'Not started';

  @Mutation
  setSeedProgress(payload: { text: string; step: number; totalSteps: number }) {
    this.seedProgressStep = payload.step;
    this.seedProgressText = payload.text;
    this.seedProgressTotalSteps = payload.totalSteps;
  }
}
