import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class ChatStoreModule extends VuexModule {
  public guestIdsWithNewMessages = [] as string[];

  @Mutation
  newChatMessageFromGuestReceived(guestId: string) {
    this.guestIdsWithNewMessages.push(guestId);
  }

  @Mutation
  setGuestIdAsRead(guestId: string) {
    this.guestIdsWithNewMessages = this.guestIdsWithNewMessages.filter((id) => id !== guestId);
  }
}
