import { RouteConfig } from 'vue-router';

const AdminPlayroomIndex = () => import('@/views/admin/playrooms/AdminPlayroomIndex.vue');
const AdminCustomerIndex = () => import('@/views/admin/users/AdminCustomerIndex.vue');
const AdminOrderIndex = () => import('@/views/admin/orders/AdminOrderIndex.vue');
const AdminOrderDetail = () => import('@/views/admin/orders/AdminOrderDetail.vue');
const AdminMessageIndex = () => import('@/views/admin/messages/AdminMessageIndex.vue');
const AdminMessageDetail = () => import('@/views/admin/messages/AdminMessageDetail.vue');
const AdminMoneyTransactionIndex = () => import('@/views/admin/moneyTransactions/AdminMoneyTransactionIndex.vue');
const AdminDashboardIndex = () => import('@/views/admin/dashboard/AdminDashboardIndex.vue');
const AdminPlayroomEdit = () => import('@/views/admin/playrooms/AdminPlayroomEdit.vue');
const AdminOwnerIndex = () => import('@/views/admin/users/AdminHostIndex.vue');
const AdminCustomerDetail = () => import('@/views/admin/users/AdminCustomerDetail.vue');
const AdminHostDetail = () => import('@/views/admin/users/AdminHostDetail.vue');
const AdminTemplateTextIndex = () => import('@/views/admin/templateTexts/AdminTemplateTextIndex.vue');
const AdminVoucherIndex = () => import('@/views/admin/vouchers/AdminVoucherIndex.vue');
const AdminVoucherEdit = () => import('@/views/admin/vouchers/AdminVoucherEdit.vue');
const AdminTemplateTextEdit = () => import('@/views/admin/templateTexts/AdminTemplateTextEdit.vue');
const AdminMigrateData = () => import('@/views/admin/migration/AdminMigrateData.vue');
const AdminControlTransaction = () => import('@/views/admin/paymentGateway/AdminControlTransaction.vue');
const AdminTransactionIndex = () => import('@/views/admin/paymentGateway/AdminTransactionIndex.vue');
const AdminModuleSubscriptionsIndex = () =>
  import('@/views/admin/moduleSubscriptions/AdminModuleSubscriptionsIndex.vue');
const AdminModuleSubscriptionEdit = () => import('@/views/admin/moduleSubscriptions/AdminModuleSubscriptionEdit.vue');
const AdminPlayroomRatingsIndex = () => import('@/views/admin/playroomRatings/AdminPlayroomRatingsIndex.vue');
const AdminAnnouncementCreate = () => import('@/views/admin/messages/AdminAnnouncementCreate.vue');
const AdminPartnerImport = () => import('@/views/admin/partner/AdminPartnerImport.vue');

export default [
  {
    path: '/admin/partnerImport',
    name: 'AdminPartnerImport',
    component: AdminPartnerImport,
  },
  {
    path: '/admin/orders/:orderId',
    name: 'AdminOrderDetail',
    component: AdminOrderDetail,
    props: true,
  },
  {
    path: '/admin/messages/announcementCreate',
    name: 'AdminAnnouncementCreate',
    component: AdminAnnouncementCreate,
  },
  {
    path: '/admin/playroomRatings',
    name: 'AdminPlayroomRatingsIndex',
    component: AdminPlayroomRatingsIndex,
  },
  {
    path: '/admin/transactions',
    name: 'AdminTransactionIndex',
    component: AdminTransactionIndex,
  },
  {
    path: '/admin/moduleSubscriptions',
    name: 'AdminModuleSubscriptionsIndex',
    component: AdminModuleSubscriptionsIndex,
  },
  {
    path: '/admin/moduleSubscription/:moduleSubscriptionId',
    name: 'AdminModuleSubscriptionEdit',
    component: AdminModuleSubscriptionEdit,
    props: true,
  },
  {
    path: '/admin/controlTransaction',
    name: 'AdminControlTransaction',
    component: AdminControlTransaction,
  },
  {
    path: '/admin/vouchers',
    name: 'AdminVoucherIndex',
    component: AdminVoucherIndex,
  },
  {
    path: '/admin/vouchers/:voucherId',
    name: 'AdminVoucherEdit',
    component: AdminVoucherEdit,
    props: true,
  },
  {
    path: '/admin/playrooms',
    name: 'AdminPlayroomIndex',
    component: AdminPlayroomIndex,
  },
  {
    path: '/admin/migrateData',
    name: 'AdminMigrateData',
    component: AdminMigrateData,
  },
  {
    path: '/admin/playrooms/:playroomId/edit',
    name: 'AdminPlayroomEdit',
    component: AdminPlayroomEdit,
    props: true,
  },
  {
    path: '/admin/templateTexts/',
    name: 'AdminTemplateTextIndex',
    component: AdminTemplateTextIndex,
  },
  {
    path: '/admin/templateTexts/edit/:templateTextId',
    name: 'AdminTemplateTextEdit',
    component: AdminTemplateTextEdit,
    props: true,
  },
  {
    path: '/admin/customers',
    name: 'AdminCustomerIndex',
    component: AdminCustomerIndex,
  },
  {
    path: '/admin/customer/:customerUserId',
    name: 'AdminCustomerDetail',
    component: AdminCustomerDetail,
    props: true,
  },
  {
    path: '/admin/host/:hostUserId',
    name: 'AdminHostDetail',
    component: AdminHostDetail,
    props: true,
  },
  {
    path: '/admin/owners',
    name: 'AdminOwnerIndex',
    component: AdminOwnerIndex,
  },
  {
    path: '/admin/orders',
    name: 'AdminOrderIndex',
    component: AdminOrderIndex,
  },
  {
    path: '/admin/messages',
    name: 'AdminMessageIndex',
    component: AdminMessageIndex,
  },
  {
    path: '/admin/messages/:messageId',
    name: 'AdminMessageDetail',
    component: AdminMessageDetail,
    props: true,
  },
  {
    path: '/admin/moneyTransactions',
    name: 'AdminMoneyTransactionIndex',
    component: AdminMoneyTransactionIndex,
    props: true,
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboardIndex',
    component: AdminDashboardIndex,
    props: true,
  },
] as RouteConfig[];
