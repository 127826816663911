


















import Vue from 'vue';

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
  },
  methods: {
    onOk() {
      this.$emit('delete');
    },
    onClose() {
      this.$emit('close');
    },
  },
  computed: {
    displayMessage() {
      if (this.message) {
        return this.message;
      } else {
        return this.$t('components.messages.DeleteConfirmationModal.message');
      }
    },
  },
});
