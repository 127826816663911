import User from '@/models/user';
import Vue from 'vue';

export default function authHeader() {
  const user = Vue.$cookies.get('user') as User;

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}
