var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"owner-calendar"},[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.calendar.OwnerCalendar.CalendarTitle'),expression:"'pages.owner.calendar.OwnerCalendar.CalendarTitle'"}]}),_c('CCardBody',[_c('div',_vm._l((_vm.playrooms),function(playroom){return _c('span',{key:playroom.id},[_c('CInputCheckbox',{staticClass:"playroom-selector",attrs:{"inline":true,"label":playroom.name,"data-playroom-id":playroom.id,"checked":_vm.selectedPlayrooms.includes(playroom.id)},on:{"change":_vm.onPlayroomSelectionChange}})],1)}),0),_c('HostCalendar',{attrs:{"orders":_vm.selectedOrders},on:{"view-change":function($event){return _vm.viewChange($event)}}}),_c('hr'),_c('div',[_vm._v(" "+_vm._s(_vm.$t('pages.owner.calendar.OwnerCalendar.table', { startDate: _vm.formatIsoDateTimeTz(_vm.calendarStartDate, 'P'), endDate: _vm.formatIsoDateTimeTz(_vm.calendarEndDate, 'P'), }))+" ")]),_c('div',[_c('CInputCheckbox',{staticClass:"show-old-orders",attrs:{"inline":true,"label":_vm.$t('pages.owner.calendar.OwnerCalendar.hideOldOrdersCheckbox'),"checked":_vm.hideOldOrders},on:{"update:checked":function($event){_vm.hideOldOrders=$event}}})],1),_c('DataTable',{attrs:{"items":_vm.ordersForTable,"fields":[
            {
              key: 'startTimeInUTC',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.checkIn'),
              sorter: true,
              filter: true,
            },
            {
              key: 'endTimeInUTC',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.checkOut'),
              sorter: true,
              filter: true,
            },
            {
              key: 'displayName',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.occupationType'),
              sorter: false,
              filter: false,
            },
            {
              key: 'playroomName',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.playroomName'),
              sorter: false,
              filter: false,
            },
            {
              key: 'customerName',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.guestName'),
              sorter: false,
              filter: false,
            },
            {
              key: 'note',
              label: _vm.$t('pages.owner.calendar.OwnerCalendar.note'),
              sorter: false,
              filter: false,
            } ],"table-filter-value":_vm.tableFilterValue,"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"startTimeInUTC",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatIsoDateTimeTz(item.startTimeInUTC, 'Pp'))+" ")])]}},{key:"endTimeInUTC",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatIsoDateTimeTz(item.endTimeInUTC, 'Pp'))+" ")])]}},{key:"displayName",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.orderType(item))+" ")])]}},{key:"playroomName",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'PublicPlayroomDetail',
                  params: { playroomId: item.playroomId },
                }}},[_vm._v(" "+_vm._s(item.playroomName)+" ")])],1)]}},{key:"customerName",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.guestName(item))+" ")])]}},{key:"note",fn:function(ref){
                var item = ref.item;
return [_c('td',[(!item.orderId)?_c('div',[_vm._v(" "+_vm._s(_vm.getNote(item))+" ")]):_vm._e(),(item.orderId)?_c('router-link',{attrs:{"to":{
                  name: 'OwnerOrderDetail',
                  params: {
                    orderId: item.orderId,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.orderId(item))+" ")]):_vm._e()],1)]}}])}),_c('OwnerPlayroomTemporalModal',{attrs:{"show":_vm.showTempModal,"title":_vm.popupTitle,"playroomId":_vm.playroomId,"tempCount":_vm.tempCount},on:{"onClose":_vm.onCloseModel}})],1)],1),_c('CCard',[_c('CCardHeader',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.calendar.OwnerCalendar.tempTitle'),expression:"'pages.owner.calendar.OwnerCalendar.tempTitle'"}]}),_c('CCardBody',[_c('CRow',_vm._l((_vm.playroomWithTempCount),function(tempCount){return _c('CCol',{key:tempCount.playroomId,attrs:{"md":"3"}},[_c('CButton',{staticClass:"add-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openTempModal(tempCount)}}},[_c('span',{staticClass:"add-text"},[_vm._v(" "+_vm._s(tempCount.playroomName)+" ")])])],1)}),1)],1)],1),_vm._l((_vm.playrooms),function(playroom){return _c('div',{key:playroom.id},[_c('CalendarSync',{attrs:{"playroom":playroom,"calendarToken":_vm.calendarToken,"publicCalendarToken":_vm.publicCalendarToken},on:{"onRefresh":_vm.loadData}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }