import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/Status/';

class AdminStatusService {
  getStatus() {
    return ApiService.get(`${API_URL}Get`);
  }
}

export default new AdminStatusService();
