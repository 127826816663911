




















import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    sorterValue: {
      default: () => ({ column: 'id', asc: true }),
    },
    tableFilter: {
      default: () => ({
        external: true,
        lazy: true,
        label: i18n.t('components.DataTable.table-filter-label'),
        placeholder: i18n.t('components.DataTable.table-filter-placeholder'),
      }),
    },
  },
  data() {
    return {
      sorterValueProxy: { column: 'id', asc: true },
      tableFilterProxy: '',
    };
  },
  mounted() {
    this.sorterValueProxy = this.sorterValue;
  },
  methods: {
    attrs() {
      var attrs = this.$attrs;
      delete attrs['sorter-value'];
      return attrs;
    },
  },
  watch: {
    sorterValueProxy: function () {
      this.$emit('update:sorterValue', this.sorterValueProxy);
    },
    tableFilterProxy: function () {
      this.$emit('update:table-filter-value', this.tableFilterProxy);
    },
  },
});
