








import Vue from 'vue';
import AlertMessage from '@/models/AlertMessage';

export default Vue.extend({
  computed: {
    alerts(): AlertMessage[] {
      return this.$store.state.AlertStoreModule.alerts;
    },
  },
});
