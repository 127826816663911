import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import NextActivityService from '@/services/users/NextActivityService';

export class NextActivity {
  type!: string;
  count!: number;
  nextEntityId!: string;
  nextEntityText!: string;
  category!: string;
  start!: string;
  end!: string;
  playroomName!: string;
  guestName!: string;
}

@Module({ namespaced: true })
export default class NextActivitiesStoreModule extends VuexModule {
  nextActivities: Array<NextActivity> = new Array<NextActivity>();

  @Mutation
  setData(data: { value: { nextActivities: Array<NextActivity> } }) {
    this.nextActivities = data.value.nextActivities;
  }

  @Action
  async removeNewOrder(orderId: string) {
    const nextActivities = this.nextActivities;
    const nextNewOrderActivity = nextActivities.find((na) => na.type == 'NewOrders');
    if (nextNewOrderActivity?.nextEntityId == orderId) {
      await this.context.dispatch('init');
    }
  }

  @Action
  async removeNewMessage(messageId: string) {
    const nextActivities = this.nextActivities;
    const activity = nextActivities.find((na) => na.type == 'UnreadMessages');
    if (activity?.nextEntityId == messageId) {
      await this.context.dispatch('init');
    }
  }

  @Action
  async removeNewChatMessage(chatMessageId: string) {
    const nextActivities = this.nextActivities;
    const activity = nextActivities.find((na) => na.type == 'UnreadChatMessages');
    if (activity?.nextEntityId == chatMessageId) {
      await this.context.dispatch('init');
    }
  }

  @Action
  async playroomCreated() {
    const nextActivities = this.nextActivities;
    const activity = nextActivities.find((na) => na.type == 'CreateFirstPlayroom');
    if (activity) {
      await this.context.dispatch('init');
    }
  }

  @Action
  async init() {
    const role = this.context.rootState.AuthStoreModule.user.role;
    switch (role) {
      case 'Owner': {
        const data = await NextActivityService.getHostData();
        this.context.commit('setData', data);
      }
      default: {
        return {};
      }
    }
  }
}
