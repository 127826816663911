<template>
  <div v-if="!isProduction" class="bg-warning p-3 mb-2 lead">
    <CIcon name="cil-warning" size="lg" class="mr-1" />
    {{ $t('components.DemoHeader.text', { environmentName }) }}
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    isProduction() {
      return process.env.VUE_APP_SHOW_NON_PRODUCTION_BANNER == 'false';
    },
    environmentName() {
      return process.env.VUE_APP_ENV_NAME;
    },
  },
});
</script>
