import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/OrderCalendar/';

class OwnerOrderCalendarService {
  deleteIcsEntries(id: string) {
    return ApiService.post(`${API_URL}DeleteIcsEntries`, {
      playroomId: id,
    });
  }

  importCalendar(id: string) {
    return ApiService.post(`${API_URL}ImportIcsCalendar`, {
      playroomId: id,
    });
  }

  saveIcsUrl(id: string, icsCalendarUrl: string) {
    return ApiService.post(`${API_URL}SaveIcsUrl`, {
      playroomId: id,
      icsCalendarUrl: icsCalendarUrl,
    });
  }

  getIcsPublicStreamUrl(calendarToken: string, playroomId: string, withImportedEvents: boolean) {
    return `${ApiService.getBaseUrl()}${API_URL}StreamPublicIcs?calendarToken=${calendarToken}&playroomId=${playroomId}&withImportedEvents=${withImportedEvents}`;
  }

  getIcsPrivateStreamUrl(calendarToken: string, playroomId: string, withImportedEvents: boolean) {
    return `${ApiService.getBaseUrl()}${API_URL}StreamPrivateIcs?calendarToken=${calendarToken}&playroomId=${playroomId}&withImportedEvents=${withImportedEvents}`;
  }

  getIndex(
    start: Date,
    end: Date,
    sortColumn: string,
    sortAsc: boolean,
    tableFilter: string,
    playroomIds: string[] = []
  ) {
    return ApiService.post(`${API_URL}Index`, {
      start: start.toISOString(),
      end: end.toISOString(),
      sortColumn,
      sortAsc,
      tableFilter,
      playroomIds,
    });
  }

  playroomWithTempCount(start: Date, end: Date) {
    return ApiService.post(`${API_URL}PlayroomWithTempCount`, {
      start: start.toISOString(),
      end: end.toISOString(),
    });
  }
  PlayroomTemporalAvailability(playroomId: string) {
    return ApiService.get(`${API_URL}PlayroomTemporalAvailability?playroomId=${playroomId}`);
  }

  editTemporalAvailability(
    id: string,
    playroomId: string,
    fromDate: string,
    untilDate: string,
    fromTime: string,
    untilTime: string,
    note: string
  ) {
    return ApiService.post(`${API_URL}EditTemporalAvailability`, {
      id: id,
      playroomId: playroomId,
      FromDate: fromDate,
      UntilDate: untilDate,
      fromTime: fromTime,
      untilTime: untilTime,
      note: note,
    });
  }

  deleteTemporalAvailability(id: string, playroomId: string) {
    return ApiService.delete(`${API_URL}DeleteTemporalAvailability?id=${id}&playroomId=${playroomId}`);
  }
}

export default new OwnerOrderCalendarService();
