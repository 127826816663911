import UserLocation from './UserLocation';

export default class SearchParameter {
  checkInDate = '';
  checkOutDate = '';
  checkOutTime = '';
  checkInTime = '';
  checkOutTimeValue = '';
  checkInTimeValue = '';
  checkInIsValid = true;
  onlyFavorites = false;
  maxDistance = '';
  minRating = '0';
  bookingType = '';
  guestCount = 2;
  houseRules: string[] = [];
  bookingPolicies: string[] = [];
  propertyTypes: string[] = [];
  features: string[] = [];
  userLocation = new UserLocation();

  sortBy!: string;
  currentPage!: string;
}
