const PublicPlayroomIndex = () => import('@/views/public/playrooms/PublicPlayroomIndex.vue');
const PublicPlayroomDetail = () => import('@/views/public/playrooms/PublicPlayroomDetail.vue');
const PublicOwnerInfo = () => import('@/views/public/infos/PublicOwnerInfo.vue');
const PublicCustomUrlPlayroom = () => import('@/views/public/playrooms/PublicCustomUrlPlayroom.vue');
const PublicImprint = () => import('@/views/public/ImprintView.vue');

export default [
  {
    path: '/public/playrooms',
    name: 'PublicPlayroomIndex',
    component: PublicPlayroomIndex,
    props: true,
  },
  {
    path: '/public/infos/owner',
    name: 'PublicOwnerInfo',
    component: PublicOwnerInfo,
  },
  {
    path: '/public/playrooms/:playroomId',
    component: PublicPlayroomDetail,
    name: 'PublicPlayroomDetail',
    props: true,
  },
  {
    path: '/playroom/:playroomUrlPath',
    name: 'PublicCustomUrlPlayroom',
    component: PublicCustomUrlPlayroom,
    props: true,
  },
  {
    path: '/public/Imprint',
    name: 'PublicImprint',
    component: PublicImprint,
    props: true,
  },
];
