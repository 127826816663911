import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import HomePage from '@/views/public/HomePage.vue';
import store from '@/store';

// Containers
import adminRoutes from './adminRoutes';
import hostRoutes from './ownerRoutes';
import newHostRoutes from './newHostsRoutes';
import publicRoutes from './publicRoutes';
import guestRoutes from './customerRoutes';
import authRoutes from './authRoutes';
import userRoutes from './userRoutes';

Vue.use(Router);

const host = window.location.host;
const parts = host.split('.');
const redirectRoutesTo = (subdomain: string, routes: RouteConfig[]): RouteConfig[] => {
  return routes.map((x) => {
    x['beforeEnter'] = (to: Route): void => {
      const envVarName = 'VUE_APP_' + subdomain.toUpperCase() + '_BASE_URL';
      const prefix = process.env[envVarName];
      let fullPath = to.fullPath;
      switch (fullPath) {
        case '/public/playrooms': {
          fullPath = '/playrooms';
          break;
        }
        case '/host/calendar': {
          fullPath = '/host/calendar/OwnerCalendar';
          break;
        }
      }
      if (fullPath.startsWith('/public/playrooms/')) {
        fullPath = fullPath.replace('/public/playrooms/', '/playrooms/id/');
      }
      if (fullPath.startsWith('/playroom/')) {
        fullPath = fullPath.replace('/playroom/', '/playrooms/');
      }
      const newUrl = prefix + fullPath;
      location.href = newUrl;
    };
    return x;
  });
};
let routes = [] as RouteConfig[];
const commonRoutes = [
  ...authRoutes,
  ...publicRoutes,
  ...userRoutes,
  ...newHostRoutes,
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
];
switch (parts[0]) {
  case 'guest':
    routes = [
      ...guestRoutes,
      ...redirectRoutesTo('host', hostRoutes),
      ...redirectRoutesTo('admin', adminRoutes),
      ...redirectRoutesTo('public', commonRoutes),
    ];
    break;
  case 'host':
    routes = [
      ...redirectRoutesTo('guest', guestRoutes),
      ...hostRoutes,
      ...redirectRoutesTo('admin', adminRoutes),
      ...redirectRoutesTo('public', commonRoutes),
    ];
    break;
  case 'admin':
    routes = [
      ...redirectRoutesTo('guest', guestRoutes),
      ...redirectRoutesTo('host', hostRoutes),
      ...adminRoutes,
      ...redirectRoutesTo('public', commonRoutes),
    ];
    break;
  default:
    routes = [
      ...redirectRoutesTo('guest', guestRoutes),
      ...redirectRoutesTo('host', hostRoutes),
      ...redirectRoutesTo('admin', adminRoutes),
      ...commonRoutes,
    ];
}
const router = new Router({
  mode: 'history',
  // linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0, x: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == '/') next();
  if (to.path.startsWith('/.well-known')) next();
  const authRequired =
    !to.path.startsWith('/playroom') &&
    !to.path.startsWith('/public') &&
    !to.path.startsWith('/auth') &&
    !to.path.startsWith('/User/ConfirmChangeEmail') && // Email Change should work without login
    to.path != '/';
  const loggedIn = Vue.$cookies.get('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    store.dispatch('AuthStoreModule/logout');
    window.location.href = process.env.VUE_APP_PUBLIC_BASE_URL + '/auth/relogin?redirect=' + to.fullPath;
  } else {
    next();
  }
});

export default router;
