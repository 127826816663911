






















import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'cilCheck',
    },
    testid: {
      type: String,
      required: false,
      default: 'notification',
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
  },
});
