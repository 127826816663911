


















import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import FeatureFilterModel from '@/models/FeatureFilterModel';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';

const validations = {
  featureFilterModel: {
    $each: {
      categoryTranslationKey: {},
      translationKey: {},
      hasFilter: {},
    },
  },
};

export default Vue.extend({
  components: { ValidatedCheckbox },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      featureFilterModel: [] as FeatureFilterModel[],
      ready: false,
    };
  },
  mounted() {
    this.loadData();
    this.updateFilterData();
  },
  watch: {
    featureFilterModel: {
      deep: true,
      handler() {
        this.emitChange();
      },
    },
    featureSearchParameter() {
      this.updateFilterData();
    },
  },
  computed: {
    featureSearchParameter(): string[] {
      return this.$store.state.SearchStoreModule.searchParameter.features;
    },
  },
  methods: {
    updateFilterData() {
      this.featureFilterModel.forEach((element) => {
        element.hasFilter = this.featureSearchParameter.indexOf(element.translationKey) > -1;
      });
    },
    emitChange() {
      let featureList = [] as string[];
      this.featureFilterModel.forEach((element) => {
        if (element.hasFilter == true) {
          featureList.push(element.translationKey.toString());
        }
      });
      const newList = JSON.stringify(featureList);
      const oldList = JSON.stringify(this.$store.state.SearchStoreModule.searchParameter.features);
      if (newList != oldList) {
        this.$store.commit('SearchStoreModule/setFeatures', featureList);
        this.$emit('change');
      }
    },

    loadData() {
      PlayroomPublicService.getFeatureList().then((res) => {
        this.featureFilterModel = res.value as FeatureFilterModel[];
        this.ready = true;
      });
    },
  },
});
