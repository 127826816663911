import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import ToastMessage from '@/models/ToastMessage';

@Module
export default class ToasterStoreModule extends VuexModule {
  toasts: ToastMessage[] = [];

  @Mutation
  addToastMessage(toast: ToastMessage) {
    this.toasts.push(toast);
  }
}
